import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { firstValueFrom, forkJoin, switchMap } from 'rxjs';
import { Indicator } from 'src/app/models/indicator';
import { InitialState } from 'src/app/models/initial-state';
import { Objective } from 'src/app/models/objetive';
import { Action } from 'src/app/models/action';
import { Evidence } from '../../models/evidence';
import { Evaluation } from '../../models/evaluation';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ServerService } from 'src/app/services/server.service';
import { UserService } from 'src/app/services/user.service';
import { DatePipe } from '@angular/common';
import { ConfirmationService, Message, MessageService } from 'primeng/api';
import { Validators } from '@angular/forms';
import { YesOrNo } from 'src/app/utils';


@Component({
  selector: 'app-base-indicator-special',
  templateUrl: './base-indicator-special.page.html',
  styleUrls: ['./base-indicator-special.page.scss'],
})
export class BaseIndicatorSpecialPage implements OnInit {
  loading: boolean = true;
  loadingCheckEmployee: boolean = false;
  loadingModal: boolean = false;
  companyId: string | null = sessionStorage.getItem('companyId');
  user: any;
  userByCompany: any[] = [];
  currentPage: number = 0;
  itemsPerPage: number = 5;
  rowsPerPage = 5;
  itemsEmployees: any[];
  itemsDepartments: any[];
  itemsFrequencies: any[];
  disabledField: boolean = true;
  checkUserByCompany: any = false;
  checkPermissionsByUser: any = false;
  loadingActions: boolean = true;
  visible: boolean = false;
  dataUser: any;
  indicator: Indicator;
  labelIcon: any;
  indicatorName: string;
  indicatorId: number = 0;
  actionEmployeSelected: boolean = false;
  yesOrNo = YesOrNo;      
  //initial state
  initialStateData: InitialState;
  initialStateDataJSON: any = {};
  initialStateId: number | null = null;
  dialogViewInitialState: boolean = false;
  dialogFormInitialState: boolean = false;
  loadingInitialState: boolean = false; 
  step1InitialState: boolean = false;
  step2InitialState: boolean = false;
  buttonStep1InitialState: boolean = false;
  buttonStep2InitialState: boolean = false; 
  updatedInitialState: boolean = false;
  buttonUpdateInitialState: boolean = false;
  buttonEditInitialState: boolean = false;
  initialStateCurrentSelect: any = {};
  formInitialState: FormGroup = this.formBuilder.group({
    indicator_id: [''],
    employee_id: [''],
    department_id: [''],
    description: [''],
    request: [''],
    data: [''],
    date: [''],
    observations: [''],
    document: [''],
    document_hash: [''],
  });
  showInitialState: string = '';  
  //objective
  objectiveData: Objective[] = [];
  objectiveDataJSON: any = {};
  dialogViewObjective: boolean = false;
  dialogFormObjective: boolean = false;
  loadingObjective: boolean = false;
  saveObjective: boolean = false;
  buttonSaveObjective: boolean = false;  
  updatedObjective: boolean = false;
  buttonUpdateObjective: boolean = false;
  buttonEditObjective: boolean = false;
  objectiveCurrentSelect: any = {};
  formObjective: FormGroup = this.formBuilder.group({
    indicator_id: [''],
    description: [''],
    data: [''],
    date: ['', Validators.required],
    observations: [''],
    document: [],
    document_hash: [''],
  });
  showObjectives: string = '';
  //action
  loadingSaveAction: boolean = false;
  formAddAction: FormGroup = this.formBuilder.group({
    indicator_id: [''],
    employee_id: ['', Validators.required],
    frequency_id: ['', Validators.required],
    department_id: [''],
    date: ['', Validators.required],
    assigned_request: [''],
    description: [''],
    demand: [''],
    document: [''],
    document_hash: [''],
    required_document: ['', Validators.required],
  });
  actionSelected: any = null;
  actionData: any[] = [];  
  //evidence
  evidenceData: Evidence[] = [];
  evidenceDataJSON: any = {};
  dialogViewEvidence: boolean = false;
  dialogFormEvidence: boolean = false;
  loadingEvidence: boolean = false;
  saveEvidence: boolean = false;
  buttonSaveEvidence: boolean = false;  
  evidenceCurrentSelect: any = {};
  formEvidence: FormGroup = this.formBuilder.group({
    action_id: [''],
    data: [''],
    observations: [''],
    date: [''],
    user_id: [''],
    document: [''],
    document_hash: [''],
  });
  uploadedFile: File | null = null;
  //evaluation
  evaluationStored: Evaluation | null;
  evidenceSearched: any;
  showEvaluateDialog: boolean = false;
  today: Date = new Date();
  formEvaluate: FormGroup = this.formBuilder.group({
    action_id: [''],
    data: [''],
    observations: [''],
    date: [''],
    user_id: [''],
    document: [''],
    document_hash: [''],
    efficacy: [false],
    evidence_id: [''],
  });   

  constructor(
    public permissionService: PermissionsService,
    private serverService: ServerService,
    private route: ActivatedRoute,
    private userService: UserService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private messageService: MessageService,        
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((params) => {
      this.indicatorId = parseInt(params['id']);
    });

    await this.getUserByCompany();
    await this.getIndicatorData();
  }


/*************** GLOBAL ****************/   


  async reloadData(){
    await this.getIndicatorData();
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      const userResponse = await firstValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );

      if (userResponse) {
        this.user = userResponse;
        const userByCompany = await firstValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );

        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
          if (this.userByCompany) {
            this.buttonEditInitialState =
              this.permissionService.checkSectionPermissions(
                this.userByCompany,
                6,
                [3]
              );
          }
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compañías de usuario:',
        err
      );
    }
  }  

  /**
   * Obtener datos del indicador y sus relaciones
   */
  async getIndicatorData() {
    // datos del indicador
    const indicatorResponse = await firstValueFrom(
      this.serverService.getData('/api/indicators/' + this.indicatorId)
    );

    if (indicatorResponse.data) {      
      this.initialStateId = indicatorResponse.data.initial_state[0]?.id ? indicatorResponse.data.initial_state[0].id : null;
      this.indicator = indicatorResponse.data;
      this.indicatorName = indicatorResponse.data.name;
      this.processIndicatorName();

      // Check a true de permiso de lectura de estado inicial
      if (
        (this.user.id !== 1 &&
          this.permissionService.checkSectionPermission(
            this.userByCompany,
            6,
            1
          ) &&
          this.permissionService.checkIndicatorPermission(
            this.indicator,
            this.user,
            1
          )) ||
        (this.user.id === 1 &&
          this.permissionService.checkSectionPermission(
            this.userByCompany,
            6,
            1
          ))
      ) {
        this.initialStateData =
          indicatorResponse.data.initial_state.length > 0
            ? indicatorResponse.data.initial_state[0]
            : null;    
        this.showInitialState = 'ALLOWED';
      } else {
        this.showInitialState = 'NOT_ALLOWED';
      }

      // Obtener objetivos
      try {
        const objetiveResponse = await firstValueFrom(
          this.serverService.getData(
            '/api/indicator/' + this.indicatorId + '/objectives'
          )
        );

        if (objetiveResponse.data) {
          this.objectiveData =
            objetiveResponse.data.length > 0
              ? objetiveResponse.data.map((item) => ({
                  ...item,
                  data: JSON.parse(item.data),
                }))
              : null;
          this.showObjectives = 'ALLOWED';
        }
      } catch (error: any) {
        console.error('Error al obtener los objetivos', error);
        if (error && error.status === 403) {
          this.showObjectives = 'NOT_ALLOWED';
        }
      }

      // Obtener datos de acciones de este indicador
      await this.getActionData();

      //checkValueInitialStateStructure
      if(this.initialStateId){
        if(indicatorResponse.data.initial_state[0].data){
          //exists - from initial_state
          this.initialStateDataJSON = JSON.parse(indicatorResponse.data.initial_state[0].data);
        }else{
          //step2 not completed - from indicator_configuration
          this.initialStateDataJSON = JSON.parse(indicatorResponse.data.indicator_configuration.config);         
        }  
      }else{
        //new - from indicator_configuration
        this.initialStateDataJSON = JSON.parse(indicatorResponse.data.indicator_configuration.config);     
      }    
      
    }

    this.loading = false;
  }

  /**
   * Función auxiliar para procesar nombre de indicador
   */
  processIndicatorName() {
    this.labelIcon = '';

    for (let index = 0; index < this.indicatorName.split(' ').length; index++) {
      if (index == 3) {
        break;
      }
      this.labelIcon =
        this.labelIcon + this.indicatorName.split(' ')[index][0].toUpperCase();
    }
  }
  

  buildTable(tableType: number) {}

  /**
   * Creación de estructura de datos para las tablas de evidencias de acciones
   */
  async getActionData() {
    try {
      const response = await firstValueFrom(
        this.serverService.getData(
          '/api/indicator/' + this.indicatorId + '/actions'
        )
      );

      if (response.data) {
        this.actionData = response.data.map((item) => ({
          ...item,
          evidences: item.evidences
            .map((subitem) => ({
              ...subitem,
              data: JSON.parse(subitem.data),
            }))
            .sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            ), // Ordenar evidencias por fecha descendente
        }));

        // Para montar datos de las tablas de evidencias
        this.buildTable(3);
      }
    } catch (err) {
      console.error('Error al obtener datos de la acción', err);
    }
    return true;
  }
  
  /**
   * Obtener listado de departamentos, empleados y frecuencias para tratarlos con desplegables
   */
  async getDropdownData() {
    try {
      const employees = this.userService.getData(
        `/api/companies/${this.companyId}/employees`
      );
      const frequencies = this.serverService.getData('/api/frequencies');
      const departments = this.userService.getData(
        `/api/companies/${this.companyId}/departments`
      );

      // Ejecutar todas las peticiones en paralelo con forkJoin
      const [employeesResponse, frequenciesResponse, departmentsResponse] =
        await firstValueFrom(forkJoin([employees, frequencies, departments]));

      // Procesar los resultados una vez que todas las peticiones se han completado
      this.itemsEmployees = employeesResponse.data
        ? employeesResponse.data.flatMap((item) =>
            item.employees.map((employee) => ({
              name: item.name,
              id: employee.id,
              employee_department_id: employee.department_id,
            }))
          )
        : [];

      this.itemsFrequencies = frequenciesResponse.data;
      this.itemsDepartments = departmentsResponse.data;
    } catch (error) {
      console.error('Error fetching dropdown data', error);
    }
  }

  /**
   * Comprobación de existencia de asociación de usuario con empresa para desplegable de responsable
   * @param event
   */
  checkUserSelected(event) {
    this.loadingCheckEmployee = true;
    this.actionEmployeSelected = true;
    const selectedEmployeeId = event.value;

    this.serverService
      .getDataUsersApi(
        `/api/companies/${this.companyId}/employees/${selectedEmployeeId}`
      )
      .pipe(
        switchMap((response) => {
          const dataEmployee = response.data;
          return this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${dataEmployee.user_id}`
          );
        })
      )
      .subscribe({
        next: (response) => {
          this.dataUser = response.data;
          const { company, employee, customer, supplier, sections } =
            this.dataUser;

          if (!company && !employee && !customer && !supplier) {
            // El usuario no existe en ningún rol
            this.checkUserByCompany = false;
            this.checkPermissionsByUser = false;
          } else {
            // El usuario existe
            this.checkUserByCompany = this.dataUser;
            if (!sections) {
              // No tiene permisos
              this.checkPermissionsByUser = false;
            } else {
              // Tiene permisos
              this.checkPermissionsByUser = sections;
              this.disabledField = false; // Activar guardar acción
            }
          }

          // Resetear variables para control de flujo
          this.loadingCheckEmployee = false;
          this.actionEmployeSelected = false;
        },
        error: (err) => {
          console.error(
            'Error al obtener los datos del usuario por empresa:',
            err
          );
          this.loadingCheckEmployee = false;
        },
      });
  }  

  /**
   * Imprimir estilos en celda para tabla
   */  
  getCellStyle(cell: any) {
    const styles: { [key: string]: string } = {};

    if (cell['background-color']) {
      styles['background-color'] = cell['background-color'];
    }

    if (cell.width) {
      styles['width'] = cell.width;
    }

    if (cell.border && cell.border.length > 0) {
      const borderObj = cell.border[0];
      if (borderObj.top && borderObj.top !== '0') {
        styles['border-top'] = `${borderObj.top}px solid black`;
      }
      if (borderObj.right && borderObj.right !== '0') {
        styles['border-right'] = `${borderObj.right}px solid black`;
      }
      if (borderObj.bottom && borderObj.bottom !== '0') {
        styles['border-bottom'] = `${borderObj.bottom}px solid black`;
      }
      if (borderObj.left && borderObj.left !== '0') {
        styles['border-left'] = `${borderObj.left}px solid black`;
      }
    }

    return styles;
  }

  /**
   * Aplicar estilo para texto vertical en celdas de la tabla
   * @param cell 
   * @returns 
   */
  getVerticalTextStyle(cell: any) {
    const styles: { [key: string]: string } = {};
  
    if (cell['vertical-text']) {
      const verticalText = cell['vertical-text'][0];
      if (verticalText['writing-mode']) {
        styles['writing-mode'] = verticalText['writing-mode'];
      }
      if (verticalText['text-orientation']) {
        styles['text-orientation'] = verticalText['text-orientation'];
      }
    }
  
    return styles;
  }

  /**
   * Volver a pantalla anterior de pila de navegación
   */
  goBack() {
    history.back();
  }


/*************** INITIAL STATE ****************/  


  /**
   * Mostrar modal ver initial state
   */
  showViewInitialState() {
    this.dialogViewInitialState = true;
  }

  /**
   * Ocultar modal ver initial state
   */  
  handleHideViewInitialState() {
    this.dialogViewInitialState = false;
  }  

  /**
   * Mostrar modal de formulario guardar initial state
   */
  async showAddInitialStateDialog(step) {
    this.loadingInitialState = true;
    // Obtengo datos dinámicos para desplegables y espero a que se resuelvan todas
    await this.getDropdownData();
    //check step
    if(step == 1){
      this.step1InitialState = true;
      this.buttonStep1InitialState = true;
    }
    if(step == 2){
      this.step2InitialState = true;
      this.buttonStep2InitialState = true;    
    }
    this.dialogFormInitialState = true;
    this.loadingInitialState = false;    
  }

  /**
   * Guardar datos initial state - step1
   */
  submitInsertInitialStateStep1() {
    this.step1InitialState = false;
    this.loadingInitialState = true;
    const formValue = this.formInitialState.value;
    const selectedDate = formValue.date;
    const selectedEmployee = this.itemsEmployees.find(
      (item) => item.id === formValue.employee_id
    );

    this.formInitialState.patchValue({
      indicator_id: this.indicatorId,
      date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
      department_id: selectedEmployee
        ? selectedEmployee.employee_department_id
        : null,
    });

    this.serverService
      .sendData(
        '/api/indicator/' + this.indicatorId + '/initial-states',
        this.formInitialState.value
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.reloadData();
            this.initialStateId = response.data.id;
            this.loadingInitialState = false;
            this.buttonStep1InitialState = false;
            this.step2InitialState = true;
            this.buttonStep2InitialState = true;
            this.formInitialState.reset();
            
          }
        },
        error: (err) => {
          console.error('Error al guardar estado inicial step1: ', err);
        },
      });
  }
  
  /**
   * Guardar datos initial state - step2
   */
  submitInsertInitialStateStep2() {
    this.step2InitialState = false;
    this.loadingInitialState = true;
    const dataJSON = this.initialStateDataJSON;

    const data = {
      data: JSON.stringify(dataJSON),
      indicator_id: this.indicatorId
    };

    this.serverService
      .updateData(
        `/api/indicator/${this.indicatorId}/initial-states/${this.initialStateId}`,
        data
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.reloadData();
            this.loadingInitialState = false;
            this.buttonStep2InitialState = false;            
            this.dialogFormInitialState = false;

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Estado inicial guardado correctamente',
            });
          }
        },
        error: (err) => {
          console.error('Error al actualizar el estado inicial step2', err);
        },
      });
  }

  /**
   * Mostrar modal de formulario actualizar initial state
   */
  async showEditInitialStateDialog() {
    this.loadingInitialState = true;
    // Obtengo datos dinámicos para desplegables y espero a que se resuelvan todas
    await this.getDropdownData();    
    this.updatedInitialState = true;
    this.buttonUpdateInitialState = true;
    this.dialogFormInitialState = true;
    this.loadingInitialState = false;
    //get data from getIndicatorData
    this.initialStateCurrentSelect = this.indicator;
    this.initialStateCurrentSelect = this.initialStateCurrentSelect.initial_state[0];
    // Rellenamos el formulario con los datos existentes
    this.formInitialState.patchValue({
      ...this.initialStateCurrentSelect,
      date: new Date(this.initialStateCurrentSelect.date),
    });    
  }  

  /**
   * Actualizar datos initial state
   */
  submitUpdateDataInitialState() {
    this.updatedInitialState = false;
    this.loadingInitialState = true;
    const dataJSON = this.initialStateDataJSON;
    const formValue = this.formInitialState.value;
    const selectedDate = formValue.date;
    const selectedEmployee = this.itemsEmployees.find(
      (item) => item.id === formValue.employee_id
    );

    this.formInitialState.patchValue({
      indicator_id: this.indicatorId,
      date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
      department_id: selectedEmployee
        ? selectedEmployee.employee_department_id
        : null,
      data: dataJSON  
    });

    this.serverService
      .updateData(
        `/api/indicator/${this.indicatorId}/initial-states/${this.initialStateId}`,
        this.formInitialState.value
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.reloadData();
            this.loadingInitialState = false;
            this.buttonUpdateInitialState = false;
            this.dialogFormInitialState = false;
            this.formInitialState.reset();    

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Estado inicial actualizado correctamente',
            });
          }
        },
        error: (err) => {
          console.error('Error al actualizar el estado inicial step2', err);
        },
      });
  }  
  
  /**
   * Manejo al cerrar modal de initial state
   */
  handleHideInitialState() {
    this.loadingInitialState = false;
    this.dialogFormInitialState = false;
    this.step1InitialState = false;
    this.step2InitialState = false;
    this.buttonStep1InitialState = false;
    this.buttonStep2InitialState = false;
    this.updatedInitialState = false;
    this.buttonUpdateInitialState = false;
    this.formInitialState.reset();     
  }  


/*************** OBJECTIVES ****************/


  /**
   * Mostrar modal ver objective
   */
  showViewObjective(id) {
    //get data from getIndicatorData
    this.objectiveCurrentSelect = this.indicator;
    this.objectiveCurrentSelect = this.objectiveCurrentSelect.objective.find(item => item.id === id);
    //get structureTable to update
    this.objectiveDataJSON = JSON.parse(this.objectiveCurrentSelect.data);
    this.dialogViewObjective = true;
  }

  /**
   * Ocultar modal ver objective
   */  
  handleHideViewObjective() {
    this.dialogViewObjective = false;
  }  

  /**
   * Mostrar modal de formulario guardar objective
   */
  async showAddObjectiveDialog() {
    this.loadingObjective = true;
    //get data from getIndicatorData
    this.objectiveCurrentSelect = this.indicator;
    //get structureTable new
    this.objectiveDataJSON = JSON.parse(this.objectiveCurrentSelect.indicator_configuration.config);
    this.saveObjective = true;
    this.buttonSaveObjective = true;
    this.dialogFormObjective = true;
    this.loadingObjective = false;    
  }

  /**
   * Guardar datos objective
   */
  submitInsertObjective() {
    const dataJSON = this.objectiveDataJSON;
    const formValue = this.formObjective.value;
    const selectedDate = formValue.date;

    if (!selectedDate) {
      this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'El campo Fecha es obligatorio',
      });
      return;
    }

    this.saveObjective = false;
    this.loadingObjective = true;
    this.formObjective.patchValue({
      indicator_id: this.indicatorId,
      date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
      data: JSON.stringify(dataJSON) 
    });

    this.serverService
      .sendData(
        '/api/indicator/' + this.indicatorId + '/objectives',
        this.formObjective.value
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.reloadData();
            this.loadingObjective = false;
            this.buttonSaveObjective = false;
            this.dialogFormObjective = false;
            this.formObjective.reset();

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Objetivo guardado correctamente',
            });            
          }
        },
        error: (err) => {
          console.error('Error al guardar objetivo: ', err);
        },
      });
  }

  /**
   * Mostrar modal de formulario actualizar objective
   */
  async showEditObjectiveDialog(id) {
    //get data from getIndicatorData
    this.objectiveCurrentSelect = this.indicator;
    this.objectiveCurrentSelect = this.objectiveCurrentSelect.objective.find(item => item.id === id);
    //get structureTable to update
    this.objectiveDataJSON = JSON.parse(this.objectiveCurrentSelect.data);    
    this.loadingObjective = true;   
    this.updatedObjective = true;
    this.buttonUpdateObjective = true;
    this.dialogFormObjective = true;
    this.loadingObjective = false;
    
    // Rellenamos el formulario con los datos existentes
    this.formObjective.patchValue({
      ...this.objectiveCurrentSelect,
      date: new Date(this.objectiveCurrentSelect.date),
    });    
  }  

  /**
   * Actualizar datos objective
   */
  submitUpdateDataObjective() {
    this.updatedObjective = false;
    this.loadingObjective = true;
    const dataJSON = this.objectiveDataJSON;
    const formValue = this.formObjective.value;
    const selectedDate = formValue.date;

    this.formObjective.patchValue({
      indicator_id: this.indicatorId,
      date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
      data: JSON.stringify(dataJSON)
    });

    this.serverService
      .updateData(
        `/api/indicator/${this.indicatorId}/objectives/${this.objectiveCurrentSelect.id}`,
        this.formObjective.value
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.reloadData();
            this.loadingObjective = false;
            this.buttonUpdateObjective = false;
            this.dialogFormObjective = false;
            this.formObjective.reset();    

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Objetivo actualizado correctamente',
            });
          }
        },
        error: (err) => {
          console.error('Error al actualizar objetivo', err);
        },
      });
  }  
  
  /**
   * Manejo al cerrar modal de objective
   */
  handleHideObjective() {
    this.loadingObjective = false;
    this.dialogFormObjective = false;
    this.saveObjective = false;
    this.buttonSaveObjective = false;
    this.updatedObjective = false;
    this.buttonUpdateObjective = false;
    this.formObjective.reset();    
  }


/*************** ACTIONS ****************/


  /**
   * Manejador de modal de formulario de acción
   */
  async showModalAddAction() {
    this.loadingModal = true;
    // Obtengo datos dinámicos para desplegables y espero a que se resuelvan todas
    await this.getDropdownData();

    // Mostrar modal de formulario de acción
    this.visible = true;
    this.loadingModal = false;
  }

  /**
   * Enviar formulario de acción al API
   */
  async saveAddAction() {
    this.loadingSaveAction = true;

    // Realizar patchValue antes de validar
    const formValue = this.formAddAction.value;
    let selectedDate = this.formAddAction.get('date')?.value;
    const selectedEmployee = this.itemsEmployees.find(
      (item) => item.id === formValue.employee_id
    );

    this.formAddAction.patchValue({
      indicator_id: Number(this.indicatorId), // Asegurarse de que sea un número
      date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
      department_id: selectedEmployee
        ? Number(selectedEmployee.employee_department_id)
        : null,
    });

    this.formAddAction.updateValueAndValidity();

    if (this.formAddAction.valid) {
      let arrayUsersPermissions: any[] = [];
      let lastAction: any, employee: any;

      try {
        // Guardar la acción
        const saveActionResponse = await firstValueFrom(
          this.serverService.sendData(
            `/api/indicator/${this.indicatorId}/actions`,
            this.formAddAction.value
          )
        );

        if (saveActionResponse.data) {
          lastAction = saveActionResponse.data;
        }

        // Obtener el indicador de la acción
        const getIndicator = await firstValueFrom(
          this.serverService.getData(`/api/indicators/${this.indicatorId}`)
        );
        const indicator = getIndicator.data;

        // Revisar permisos de usuarios para el indicador
        const usersPermissionsIndicator = indicator.permissions;
        for (let k in usersPermissionsIndicator) {
          if (usersPermissionsIndicator[k].permission_id === 4) {
            arrayUsersPermissions.push(usersPermissionsIndicator[k].user_id);
          }
        }

        // Obtener el empleado de la acción
        const getEmployee = await firstValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/employees/${lastAction.employee_id}`
          )
        );
        if (getEmployee.data) {
          employee = getEmployee.data;
        }

        // Insertar permiso de indicador para el empleado
        await firstValueFrom(
          this.serverService.sendData(
            `/api/indicator/${this.indicatorId}/user/${employee.user_id}/company/${this.companyId}/permissions/[1]`
          )
        );

        // Añadir el ID del empleado a la primera posición de los permisos
        arrayUsersPermissions = [employee.user_id, ...arrayUsersPermissions];

        // Guardar permisos de acción para el empleado y los admins
        for (let i = 0; i < arrayUsersPermissions.length; i++) {
          const permissionsInAction = i === 0 ? [1] : [1, 2, 3, 4]; // 1 para empleado, [1,2,3,4] para admin
          await firstValueFrom(
            this.serverService.sendData(
              `/api/action/${lastAction.id}/user/${arrayUsersPermissions[i]}/company/${this.companyId}/permissions/[${permissionsInAction}]`
            )
          );
        }

        // Recargar datos y notificar al usuario
        this.getActionData();
        this.messageService.add({
          severity: 'success',
          summary: 'OK',
          detail: 'Acción registrada',
        });
        this.visible = false;

        // Guardar registro cambios en indicador actual
        // this.saveIndicadorChangeHistory(8, 'store'); //action, guardar
      } catch (err) {
        console.error('Error en el proceso de guardar la acción:', err);
        this.loadingSaveAction = false;
      } finally {
        this.loadingSaveAction = false;
      }
    } else {
      this.loadingSaveAction = false;
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los datos del formulario',
      });
    }
  }

  /**
   * Manejador de cierre de modal de acción
   */
  closeModalAction() {
    this.formAddAction.reset();
    this.loadingSaveAction = false;
    this.checkUserByCompany = false;
    this.checkPermissionsByUser = false;
    this.disabledField = true;
    this.actionEmployeSelected = false;
  }  


/*************** EVIDENCES ****************/


  /**
   * Mostrar modal ver objective
   */
  showViewEvidence(action,evidence) {
    //get data from getIndicatorData
    this.evidenceCurrentSelect = this.indicator;
    this.evidenceCurrentSelect = this.evidenceCurrentSelect.actions.find(item => item.id === action);
    this.evidenceCurrentSelect = this.evidenceCurrentSelect.evidences.find(item => item.id === evidence);
    //get structureTable to update
    this.evidenceDataJSON = JSON.parse(this.evidenceCurrentSelect.data);
    this.dialogViewEvidence = true;
  }

  /**
   * Ocultar modal ver Evidence
   */  
  handleHideViewEvidence() {
    this.dialogViewEvidence = false;
  }  

  /**
   * Mostrar modal de formulario guardar Evidence
   */
  async showAddEvidenceDialog(action) {
    this.actionSelected = action;
    this.loadingEvidence = true;
    //get data from getIndicatorData
    this.evidenceCurrentSelect = this.indicator;
    //get structureTable new
    this.evidenceDataJSON = JSON.parse(this.evidenceCurrentSelect.indicator_configuration.config);
    this.saveEvidence = true;
    this.buttonSaveEvidence = true;
    this.dialogFormEvidence = true;
    this.loadingEvidence = false;    
  }

  /**
   * Guardar datos Evidence
   */
  submitInsertEvidence() {
    if (this.actionSelected.required_document && !this.uploadedFile) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar un documento',
      });
    } 

    this.saveEvidence = false;
    this.loadingEvidence = true;
    const dataJSON = this.evidenceDataJSON;
    const formValue = this.formEvidence.value;
    const selectedDate = this.datePipe.transform(formValue.date, 'yyyy-MM-dd');   

    //Instancia de FormData para poder enviar documento
    const formData = new FormData();
    formData.append('action_id', this.actionSelected.id.toString());
    if (formValue.observations) {
      formData.append('observations', formValue.observations.toString());
    }
    if (selectedDate) {
      formData.append('date', selectedDate);
    }
    if (this.user.id) {
      formData.append('user_id', this.user.id.toString());
    }
    formData.append('data', JSON.stringify(dataJSON));
    if (this.uploadedFile) {
      formData.append('document', this.uploadedFile);
    } 

    // this.formEvidence.patchValue({
    //   action_id: this.actionSelected.id,
    //   date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
    //   data: JSON.stringify(dataJSON),
    //   user_id: this.user.id 
    // });

    this.serverService
      .sendData(
        `/api/indicator/${this.indicatorId}/action/${this.actionSelected.id}/evidences`,
        formData
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.reloadData();
            this.loadingEvidence = false;
            this.buttonSaveEvidence = false;
            this.dialogFormEvidence = false;
            this.formEvidence.reset();

            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Evidencia guardada correctamente',
            });            
          }
        },
        error: (err) => {
          console.error('Error al guardar Evidencia: ', err);
        },
      });
  } 
  
  /**
   * Manejador de subida de archivo de evidencia
   * @param event
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }
  
  /**
   * Manejador de eliminación de archivo de evidencia
   * @param event
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }  
  
  /**
   * Manejo al cerrar modal de Evidence
   */
  handleHideEvidence() {
    this.loadingEvidence = false;
    this.dialogFormEvidence = false;
    this.saveEvidence = false;
    this.buttonSaveEvidence = false;
    this.uploadedFile = null;
    this.formEvidence.reset();   
  }


/*************** EVALUATIONS ****************/


  /**
   * Manejador para mostrar modal de evaluación de evidencia
   */
  showEvaluateModal(
    action: Action,
    evidence: Evidence,
    evaluationLength: number
  ) {
    this.evidenceSearched = [];
    this.evaluationStored = null;
    this.actionSelected = action;

    this.showEvaluateDialog = true;
    this.evidenceSearched = evidence;

    // Si la evidencia tiene ya una evaluación, la actualizamos
    if (evaluationLength > 0) {
      if (this.evidenceSearched['evaluations'].length > 0) {
        this.evaluationStored = this.evidenceSearched.evaluations[0]; // esta evidencia tiene una evaluación guardada
        if (this.evaluationStored) {
          this.formEvaluate.patchValue(this.evaluationStored);
        }
      }
    }
  }

  /**
   * Enviar al API evaluación de la evidencia
   */
  sendEvidenceEvaluation() {
    const selectedDate = this.today;
    this.formEvaluate.markAllAsTouched();

    this.formEvaluate.patchValue({
      evidence_id: this.evidenceSearched.id,
      date: this.datePipe.transform(selectedDate, 'yyyy-MM-dd'),
      user_id: this.user.id,
    });

    this.loadingModal = true;

    // Si hay evaluación guardada, se actualiza
    if (this.evaluationStored) {
      this.serverService
        .updateData(
          `/api/indicator/${this.indicatorId}/action/${this.actionSelected.id}/evidence/${this.evidenceSearched.id}/evaluations/${this.evaluationStored.id}`,
          this.formEvaluate.value
        )
        .subscribe({
          next: async (response) => {
            if (response.data) {
              this.evaluationStored = null; // Reinicio este valor por si vamos a otra evidencia
              await this.getIndicatorData();
              this.showEvaluateDialog = false;
              this.loadingModal = false;
              this.formEvaluate.reset();

              // Guardar registro cambios en indicador actual
              // this.saveIndicadorChangeHistory(11, 'update'); //evaluation, actualizar

              return this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Evidencia evaluada correctamente',
              });
            }
          },
          error: (err) => {
            console.error(
              'Error al registrar la evaluación de la evidencia',
              err
            );
          },
        });
    } else {
      // Sino, se crea una nueva evaluación
      this.serverService
        .sendData(
          `/api/indicator/${this.indicatorId}/action/${this.actionSelected.id}/evidence/${this.evidenceSearched.id}/evaluations`,
          this.formEvaluate.value
        )
        .subscribe({
          next: async (response) => {
            if (response.data) {
              await this.getIndicatorData();
              this.showEvaluateDialog = false;
              this.loadingModal = false;
              this.formEvaluate.reset();

              // Guardar registro cambios en indicador actual
              // this.saveIndicadorChangeHistory(11, 'store'); //evaluation, guardar

              return this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Evidencia evaluada correctamente',
              });
            }
          },
          error: (err) => {
            console.error(
              'Error al registrar la evaluación de la evidencia',
              err
            );
          },
        });
    }
  }  

  /**
   * Abrir documento
   */
  openDocument(document: any) {
    window.open(document, '_blank');
  }

}
