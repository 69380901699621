import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { ServerService } from 'src/app/services/server.service';
import { UtilitiesService } from 'src/app/services/utilities.service';
import { formOptions } from 'src/app/utils';

@Component({
  selector: 'app-users-associates',
  templateUrl: './users-associates.page.html',
  styleUrls: ['./users-associates.page.scss'],
})
export class UsersAssociatesPage implements OnInit {
  formAssociate: FormGroup;
  companyId: string | null = null;
  loading: boolean = true;
  associates: any[] = [];
  dialogAssociate: boolean = false;
  selectedAssociate: any;
  mainPhoto: File | undefined;
  mainStringPhoto: any;
  edit: boolean = false;
  formOptions = formOptions;
  formSelected: number = 1;
  boolAzure: boolean = false;
  loadingForm: boolean = false;
  user: any;

  constructor(
    private serverService: ServerService,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.companyId = sessionStorage.getItem('companyId');
  }

  ngOnInit() {
    this.formAssociate = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.minLength(8)],
      repeat_password: [''],
    });

    this.getAssociateUsers();
  }

  /**
   * Obtener listado de usuario asociados de una empresa
   */
  getAssociateUsers() {
    this.serverService
      .getDataUsersApi(`/api/companies/${this.companyId}/users/associates`)
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.associates = response.data ? response.data : [];
          }
          this.loading = false;
        },
        error: (err) => {
          console.error('Error al obtener empleados', err);
          this.loading = false;
        },
      });
  }

  /**
   * Manejador de modal de usuario
   * @param user
   */
  showEmployeeDialog(user?) {
    if (user) {
      this.formAssociate.patchValue(user);
      this.selectedAssociate = user;
      this.mainStringPhoto = user.image;
    }

    this.serverService.getAzureUsersEnv().subscribe((data) => {
      this.boolAzure = data ? true : false;
      this.dialogAssociate = true;
      this.edit = user ? true : false;
    });
  }

  /**
   * Manejador de cierre de modal de asociado
   */
  closeAssociateModal() {
    this.formAssociate.reset();
  }

  /**
   * Manejador para imágen principal
   * @param $event
   * @returns
   */
  public handleUploadPhoto($event: any) {
    try {
      const files: FileList = $event.target.files;
      this.mainPhoto = UtilitiesService.renameFile(
        files[0],
        new Date().getTime().toString()
      );
      var reader = new FileReader();
      if (
        this.mainPhoto.type === 'image/jpeg' ||
        this.mainPhoto.type === 'image/png'
      ) {
        reader.onloadend = async () => {
          $event.target.value = '';
          this.mainStringPhoto = String(reader.result);
        };
        reader.readAsDataURL(this.mainPhoto);
      } else {
      }
    } catch (error) {
      console.error('Error al manejar imagen adjunta', error);
    }
  }

  /**
   * Eliminar foto adjunta al formulario
   */
  public deletePhoto() {
    this.mainPhoto = undefined;
    this.mainStringPhoto = undefined;
  }

  /**
   * Enviar formulario de usuario básico y luego como asociado
   */
  async onSubmitUser() {
    this.formAssociate.markAllAsTouched();
    if (this.formAssociate.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise los campos del formulario',
      });
    }

    this.loadingForm = true;

    // Procesamos datos del formulario como FormData
    const formValue = this.formAssociate.value;
    const formData = new FormData();
    if (this.mainPhoto) {
      formData.append('image', this.mainPhoto);
    }
    formData.append('name', formValue.name.toString());
    formData.append('email', formValue.email.toString());
    if (formValue.password) {
      formData.append('password', formValue.password.toString());
    }

    if (formValue.repeat_password) {
      formData.append('passwordConfirm', formValue.repeat_password.toString());
    }

    // Creamos o editamos usuario básico
    try {
      // EDITAR
      if (this.edit) {
        const userResponse = await lastValueFrom(
          this.serverService.sendDataUsersApi(
            '/api/users/' + this.selectedAssociate.id,
            formData
          )
        );

        if (userResponse) {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Usuario actualizado con éxito',
          });
          this.getAssociateUsers();
          this.loadingForm = false;
          this.dialogAssociate = false;
        }
      }
      // CREAR
      else {
        const userResponse = await lastValueFrom(
          this.serverService.sendDataUsersApi('/api/users', formData)
        );

        if (userResponse) {
          this.user = userResponse.data;
          let userId: any[] = [];
          userId.push(this.user.id);

          const associateResponse = await lastValueFrom(
            this.serverService.sendDataUsersApi(
              `/api/companies/${this.companyId}/users/${JSON.stringify(userId)}`
            )
          );

          if (associateResponse) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail: 'Usuario creado con éxito',
            });
            this.getAssociateUsers();
            this.loadingForm = false;
            this.dialogAssociate = false;
          }
        }
      }
    } catch (err: any) {
      console.error('Error al crear/editar usuario', err);
      this.loadingForm = false;
      if (err.error.errors.email) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Email ya en uso, inténtelo con otro diferente',
        });
      }
    }
  }

  /**
   * Manejador de alerta para eliminar usuario
   * @param userId
   */
  onDeleteClick(event, user) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este usuario asociado?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.serverService
          .deleteDataUsersApi(
            `/api/companies/${this.companyId}/users/${user.id}`
          )
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Usuario eliminado correctamente',
                });
                this.getAssociateUsers();
              }
            },
            error: (error) => {
              console.error('Error al eliminar el usuario', error);
            },
          });
      },
    });
  }
}
