<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>

  <ion-row style="margin-top: -6rem !important">
    <div class="word-container">
      @if(user && userByCompany && userByCompany?.sections && (user?.id === 1 ||
      (user?.id !== 1 && permissionService.checkSectionPermission(userByCompany,
      17, 1)))){
      <button
        pButton
        (click)="handleFilterModal()"
        [outlined]="true"
        icon="pi pi-file-word"
        label="Descargar informe Word"
        [loading]="loadingModal"
      ></button>
      }
    </div>
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <p-card header="Resumen indicadores">
        @if(!loading){
        <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
          <div class="spinner-container">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </ion-col>
        } @else{
        <ion-row class="chart-container">
          <ion-col class="col-box-pie" size-xs="12" size-sm="12" size-md="4">
            <h3>Indicadores por tipo</h3>
            <p-chart
              type="pie"
              [data]="chartPieData"
              [options]="chartPieOptions"
            />
          </ion-col>
          <ion-col class="col-box-bar" size-xs="12" size-sm="12" size-md="8">
            <h3>Total indicadores por sección</h3>
            <p-chart
              type="bar"
              [data]="chartBarData"
              [options]="chartBarOptions"
            />
          </ion-col>
        </ion-row>

        <!--inicio bucle primer nivel-->
        <p-accordion *ngFor="let parentKey of model['items']">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <i class=" {{ parentKey.icon }}"></i>
              <span
                class="ml-2"
                style="font-family: 'Inter var', sans-serif !important"
              >
                {{ parentKey.label }}
              </span>
              @if(user.id === 1 || (user.id !== 1 &&
              permissionService.checkSectionPermission(userByCompany, 17, 1))){
              <p-button
                pTooltip="Generar informe word de indicadores de este nivel"
                icon="pi pi-file-word"
                (click)="downloadGrandchildrenWords(parentKey, 1); $event.stopPropagation();"
              >
              </p-button>
              <!-- <p-button
                label="Descargar XBRL"
                (click)="downloadGrandchildrenXBRL(parentKey); $event.stopPropagation();"
              >
              </p-button> -->
              }

              <div class="totales">
                <span>
                  <i class="pi pi-database"></i>
                  <span
                    class="allindicators"
                    pTooltip="Total Indicadores"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].total }}
                  </span>
                </span>
                <span>
                  <i class="pi pi-file-word"></i>
                  <span
                    class="allindicatorsQualitative"
                    pTooltip="Total Cualitativos"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cualitativos }}
                  </span>
                </span>
                <span>
                  <i class="pi pi-check"></i>
                  <span
                    class="allindicatorsValid"
                    pTooltip="Total Cualitativos Validados"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cualitativosValid }}
                  </span>
                </span>
                <span>
                  <i class="pi pi-chart-bar"></i>
                  <span
                    class="allindicatorsQuantitative"
                    pTooltip="Total Cuantitativos"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cuantitativos }}
                  </span>
                </span>
                <span>
                  <i class="pi pi-check"></i>
                  <span
                    class="allindicatorsValid"
                    pTooltip="Total Cuantitativos Validados"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cuantitativosValid }}
                  </span>
                </span>
                <span>
                  <i class="pi pi-info-circle"></i>
                  <span
                    class="allindicatorsGap"
                    pTooltip="Total No Asignados GAP"
                    tooltipPosition="top"
                  >
                    {{
                    totales[parentKey.label].gap
                    }}
                  </span>
                </span>
                <span>
                  <i class="pi pi-check"></i>
                  <span
                    class="allindicatorsValid"
                    pTooltip="Total No Asignados GAP Validados"
                    tooltipPosition="top"
                  >
                    {{
                    totales[parentKey.label].gapValid
                    }}
                  </span>
                </span>                
              </div>
            </ng-template>

            <!--inicio bucle segundo nivel-->
            <p-accordion *ngFor="let childKey of parentKey['items']">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <i class=" {{ childKey.icon }}"></i>
                  <span
                    class="ml-2"
                    style="
                      font-weight: 400 !important;
                      font-family: 'Inter var', sans-serif !important;
                    "
                  >
                    {{ childKey.label }}
                  </span>
                  @if(user.id === 1 || (user.id !== 1 &&
                  permissionService.checkSectionPermission(userByCompany, 17, 1))){
                  <p-button
                    pTooltip="Generar informe word de indicadores de este nivel"
                    icon="pi pi-file-word"
                    (click)="downloadGrandchildrenWords(childKey, 2); $event.stopPropagation();"
                  >
                  </p-button>
                  }

                  <div class="totales">
                    <span>
                      <i class="pi pi-database"></i>
                      <span
                        class="allindicators"
                        pTooltip="Total Indicadores"
                        tooltipPosition="top"
                      >
                        {{ totales[parentKey.label][childKey.label].total }}
                      </span>
                    </span>
                    <span>
                      <i class="pi pi-file-word"></i>
                      <span
                        class="allindicatorsQualitative"
                        pTooltip="Total Cualitativos"
                        tooltipPosition="top"
                      >
                        {{ totales[parentKey.label][childKey.label].cualitativos
                        }}
                      </span>
                    </span>
                    <span>
                      <i class="pi pi-check"></i>
                      <span
                        class="allindicatorsValid"
                        pTooltip="Total Cualitativos Validados"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].cualitativosValid
                        }}
                      </span>
                    </span>
                    <span>
                      <i class="pi pi-chart-bar"></i>
                      <span
                        class="allindicatorsQuantitative"
                        pTooltip="Total Cuantitativos"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].cuantitativos
                        }}
                      </span>
                    </span>
                    <span>
                      <i class="pi pi-check"></i>
                      <span
                        class="allindicatorsValid"
                        pTooltip="Total Cualitativos Validados"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].cuantitativosValid
                        }}
                      </span>
                    </span>
                    <span>
                      <i class="pi pi-info-circle"></i>
                      <span
                        class="allindicatorsGap"
                        pTooltip="Total No Asignados GAP"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].gap
                        }}
                      </span>
                    </span>
                    <span>
                      <i class="pi pi-check"></i>
                      <span
                        class="allindicatorsValid"
                        pTooltip="Total No Asignados GAP Validados"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].gapValid
                        }}
                      </span>
                    </span>                    
                  </div>
                </ng-template>

                <!--inicio bucle tercer nivel-->
                <p-accordion *ngFor="let grandchild of childKey['items']">
                  <p-accordionTab>
                    <ng-template pTemplate="header">
                      <i class=" {{ grandchild.icon }}"></i>
                      <span
                        class="ml-2"
                        style="
                          font-weight: 400 !important;
                          font-family: 'Inter var', sans-serif !important;
                        "
                      >
                        {{ grandchild.label }}
                      </span>
                      @if(user.id === 1 || (user.id !== 1 &&
                      permissionService.checkSectionPermission(userByCompany, 17, 1))){
                      <p-button
                        pTooltip="Generar informe word de indicadores de este nivel"
                        icon="pi pi-file-word"
                        (click)="downloadGrandchildrenWords(grandchild, 3); $event.stopPropagation();"
                      >
                      </p-button>
                      }

                      <div class="totales">
                        <span>
                          <i class="pi pi-database"></i>
                          <span
                            class="allindicators"
                            pTooltip="Total Indicadores"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].total
                            }}
                          </span>
                        </span>
                        <span>
                          <i class="pi pi-file-word"></i>
                          <span
                            class="allindicatorsQualitative"
                            pTooltip="Total Cualitativos"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cualitativos
                            }}
                          </span>
                        </span>
                        <span>
                          <i class="pi pi-check"></i>
                          <span
                            class="allindicatorsValid"
                            pTooltip="Total Cualitativos Validados"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cualitativosValid
                            }}
                          </span>
                        </span>
                        <span>
                          <i class="pi pi-chart-bar"></i>
                          <span
                            class="allindicatorsQuantitative"
                            pTooltip="Total Cuantitativos"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cuantitativos
                            }}
                          </span>
                        </span>
                        <span>
                          <i class="pi pi-check"></i>
                          <span
                            class="allindicatorsValid"
                            pTooltip="Total Cualitativos Validados"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cuantitativosValid
                            }}
                          </span>
                        </span>
                        <span>
                          <i class="pi pi-info-circle"></i>
                          <span
                            class="allindicatorsGap"
                            pTooltip="Total No Asignados GAP"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].gap
                            }}
                          </span>
                        </span>
                        <span>
                          <i class="pi pi-check"></i>
                          <span
                            class="allindicatorsValid"
                            pTooltip="Total No Asignados GAP Validados"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].gapValid
                            }}
                          </span>
                        </span>                        
                      </div>
                    </ng-template>

                    <!--inicio bucle indicadores-->
                    @for(indicator of grandchild.indicatorNames; track
                    indicator){
                    <ion-item class="item-indicator" lines="none">
                      @if(indicator.type){
                      <!-- indicador con tipo -->
                      <i
                        class="pi pi-sign-in click"
                        (click)="redirect(indicator)"
                      >
                        <span
                          class="click"
                          style="
                            font-weight: 400 !important;
                            font-family: 'Inter var', sans-serif !important;
                          "
                        >
                          {{ indicator.name }}
                        </span>
                      </i>
                      } @else {
                      <!-- indicador sin tipo -->
                      <div
                        pTooltip="Debe definir el tipo de indicador antes de acceder a él"
                      >
                        <i class="pi pi-sign-in click">
                          <span
                            class="click"
                            style="
                              font-weight: 400 !important;
                              font-family: 'Inter var', sans-serif !important;
                            "
                          >
                            {{ indicator.name }}
                          </span>
                        </i>
                      </div>

                      }
                      <!--  -->
                      @if(hasBothStandards(indicator, 1, 2)){
                      <ion-avatar slot="end">
                        <img src="assets/images/gri.png" alt="" />
                      </ion-avatar>
                      <ion-avatar slot="end">
                        <img src="assets/images/esrs.png" alt="" />
                      </ion-avatar>
                      } @else if(hasStandard(indicator, 1)){
                      <ion-avatar slot="end">
                        <img src="assets/images/esrs.png" alt="" />
                      </ion-avatar>
                      } @else if (hasStandard(indicator, 2)){
                      <ion-avatar slot="end">
                        <img src="assets/images/gri.png" alt="" />
                      </ion-avatar>
                      }
                      <!-- qualitative -->
                      @if(indicator.type == 'qualitative') {
                        @if(indicator.validated){
                      <p-chip
                        class="custom-chip-success"
                        icon="pi pi-check"
                        pTooltip="Indicador validado"
                        tooltipPosition="top"
                        label="Validado"
                        slot="end"
                      ></p-chip>
                        } @else {
                      <p-chip
                        class="custom-chip-danger"
                        icon="pi pi-check"
                        pTooltip="Indicador no validado"
                        tooltipPosition="top"
                        label="No validado"
                        slot="end"
                      ></p-chip>
                        }
                      }
                      <!-- quantitative -->
                      @if(indicator.type === 'quantitative') {
                        @if(indicator.validated){
                      <div class="traffic-light" slot="end">
                        <div class="light grey"></div>
                        <div class="light grey"></div>
                        <div class="light green"></div>
                      </div>
                        } @else {
                      <div class="traffic-light" slot="end">
                        <div class="light red"></div>
                        <div class="light grey"></div>
                        <div class="light grey"></div>
                      </div>
                        } 
                      }
                      <!-- gap -->
                      @if(indicator.type == "gap") {
                        <p-chip
                          class="custom-chip-primary"
                          icon="pi pi-check"
                          pTooltip="Indicador No aplica"
                          tooltipPosition="top"
                          label="No aplica"
                          slot="end"
                        ></p-chip>
                        <!-- @if(indicator.validated){
                      <p-chip
                        class="custom-chip-success"
                        icon="pi pi-check"
                        pTooltip="Indicador validado"
                        tooltipPosition="top"
                        label="Validado"
                        slot="end"
                      ></p-chip>
                        } @else {
                      <p-chip
                        class="custom-chip-danger"
                        icon="pi pi-check"
                        pTooltip="Indicador no validado"
                        tooltipPosition="top"
                        label="No validado"
                        slot="end"
                      ></p-chip>
                        } -->
                      }                      
                    </ion-item>
                    }
                  </p-accordionTab>
                </p-accordion>
              </p-accordionTab>
            </p-accordion>
          </p-accordionTab>
        </p-accordion>
        }
      </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Modal para filtro de evidencias -->
<p-dialog
  header="Filtro de evidencias"
  [(visible)]="dialogFilter"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '60vw' }"
  (onHide)="rangeDates = []"
>
  <div>
    <ion-grid>
      <ion-row>
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Opciones de descarga de informe</label>
          <div class="mt-1">
            <p-dropdown
              appendTo="body"
              optionLabel="label"
              optionValue="value"
              placeholder="Seleccione una opción de descarga"
              [options]="wordFilterOptions"
              [(ngModel)]="selectedFilter"
            />
          </div>
        </ion-col>
        <!-- Por rango de fechas -->
        @if(selectedFilter === 2){
        <ion-col size-xs="12" size-sm="12" size-md="12">
          <label>Rango de fechas</label>
          <div class="mt-1">
            <p-calendar
              class="calendar-form"
              appendTo="body"
              [(ngModel)]="rangeDates"
              selectionMode="range"
              dateFormat="dd/mm/yy"
              [readonlyInput]="true"
            />
          </div>
        </ion-col>
        }
      </ion-row>
    </ion-grid>
  </div>

  <ng-template pTemplate="footer">
    @if(selectedFilter === 2 && rangeDates.length > 0){
    <p-button
      label="Limpiar filtro"
      severity="danger"
      (click)="rangeDates = []"
    >
    </p-button>
    }
    <p-button
      label="Descargar"
      [loading]="loadingWord"
      (click)="onClickDownloadWord()"
    >
    </p-button>
    <p-button
      label="Descargar todos"
      disabled
      [loading]="loadingWord"
      (click)="downloadIndicatorsWord()"
    >
    </p-button>
    <p-button
      label="Descargar estándar GRI"
      disabled
      [loading]="loadingWord"
      (click)="downloadIndicatorsWord()"
    >
    </p-button>
  </ng-template>
</p-dialog>
