import { DatePipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { lastValueFrom } from 'rxjs';
import { ConcernedParty } from 'src/app/models/concerned-party';
import { SystemScope } from 'src/app/models/system-scope';
import { PermissionsService } from 'src/app/services/permissions.service';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-system-scopes',
  templateUrl: './system-scopes.page.html',
  styleUrls: ['./system-scopes.page.scss'],
})
export class SystemScopesPage implements OnInit {
  @ViewChild('dt') table: Table;
  @ViewChild('filter') filter: ElementRef;
  @ViewChild('content') content: ElementRef;
  loading: boolean = true;
  loadingForm: boolean = false;
  companyId: string | null;
  systemScopes: SystemScope[] = [];
  forbidden: boolean = false;
  dialogSystemScope: boolean = false;
  selectedSystemScope: ConcernedParty | null;
  edit: boolean = false;
  form: FormGroup;
  uploadedFile: File | null;
  uploadedFileString: string;
  user: any;
  userByCompany: any;

  constructor(
    private serverService: ServerService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private permissionService: PermissionsService,
    private fb: FormBuilder
  ) {
    this.companyId = sessionStorage.getItem('companyId');
  }

  async ngOnInit() {
    this.form = this.fb.group({
      company_id: [''],
      responsible: ['', Validators.required],
      document: [''],
      document_hash: [''],
    });
    await this.getUserByCompany();
    this.getSystemScopes();
  }

  /**
   * Obtener registros de alcances del sistema
   */
  getSystemScopes() {
    this.serverService.getData('/api/system-scopes').subscribe({
      next: (response) => {
        if (response.data) {
          this.systemScopes = response.data;
        } else {
          this.systemScopes = [];
        }
        this.loading = false;
      },
      error: (err) => {
        console.error(
          'Error al obtener los registros de alcances del sistema ',
          err
        );
        if (err.status === 403) {
          // Error de permisos
          this.forbidden = true;
        }
        this.loading = false;
      },
    });
  }

  /**
   * Manejador de modal de creación/edición de registro de alcance del sistema
   */
  showSystemScopeDialog(systemScope: SystemScope) {
    if (systemScope) {
      this.selectedSystemScope = systemScope;
      this.form.patchValue(systemScope);
      this.uploadedFileString = systemScope.document
        ? systemScope.document
        : '';
    }

    this.dialogSystemScope = true;
    this.edit = systemScope ? true : false;
  }

  /**
   * Manejador de cierre de modal de creación/edición de registro de alcance de sistema
   */
  handleCloseSystemScopeModal() {
    this.selectedSystemScope = null;
    this.form.reset();
    this.uploadedFile = null;
    this.edit = false;
  }

  /**
   * Manejador de carga de archivo seleccionado
   */
  onFileSelect(event: any): void {
    const selectedFiles = event.files;

    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      this.uploadedFile = selectedFile;
    }
  }

  /**
   * Manejador de eliminación de archivo seleccionado
   */
  deleteFileSelected(event) {
    this.uploadedFile = null;
  }

  /**
   * Envio de formulario de creación/edición de registro de alcance de sistema
   */
  async onSubmitForm() {
    await this.getUserByCompany();

    // Permisos de edición
    const hasEditPermission = this.permissionService.checkSectionPermission(
      this.userByCompany,
      20,
      3
    );
    // Permisos de creación
    const hasCreatePermission = this.permissionService.checkSectionPermission(
      this.userByCompany,
      20,
      2
    );

    // Check a true de permisos de creación o edición
    if (
      (!this.edit && hasCreatePermission) ||
      (this.edit && hasEditPermission)
    ) {
      this.form.markAllAsTouched();

      if (this.form.invalid) {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Revise los campos del formulario',
        });
      }

      // Procesar datos del formulario
      const formValue = this.form.value;
      const formData = new FormData();

      this.appendFormData(formData, formValue);
      this.loadingForm = true;

      // Comprobación para guardar nuevo registro o editar un existente
      if (this.edit) {
        this.updateSystemScope(formData);
      } else if (hasCreatePermission) {
        this.saveSystemScope(formData);
      } else {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'No tiene permisos para crear un registro en esta sección',
        });
      }
    } else {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'No tiene permisos para realizar esta acción',
      });
    }
  }

  /**
   * Función auxiliar para realizar la inserción de datos en FormData
   * @param formData
   * @param formValue
   */
  appendFormData(formData: FormData, formValue: any) {
    formData.append(
      'company_id',
      formValue.company_id ? formValue.company_id.toString() : ''
    );
    formData.append('responsible', formValue.responsible.toString());

    if (this.uploadedFile) {
      formData.append('document', this.uploadedFile, this.uploadedFile.name);
    }
  }

  /**
   * Función auxiliar para realizar la actualización de un registro de alcance de sistema
   * @param formData
   */
  updateSystemScope(formData: FormData) {
    this.serverService
      .updatePostData(
        `/api/system-scopes/${this.selectedSystemScope?.id}`,
        formData
      )
      .subscribe({
        next: (response) => {
          if (response.data) {
            this.messageService.add({
              severity: 'success',
              summary: 'OK',
              detail:
                'Registro de alcance de sistema actualizado correctamente',
            });
            this.dialogSystemScope = false;
            this.loadingForm = false;
            this.getSystemScopes();
          }
        },
        error: (err) => {
          console.error(
            'Error al actualizar el registro de alcance de sistema',
            err
          );
          this.loadingForm = false;
        },
      });
  }

  /**
   * Función auxiliar para realizar la inserción de un nuevo registro de alcance de sistema
   * @param formData
   */
  saveSystemScope(formData: FormData) {
    this.serverService.sendData(`/api/system-scopes`, formData).subscribe({
      next: (response) => {
        if (response.data) {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Registro de alcance de sistema guardado correctamente',
          });
          this.getSystemScopes();
          this.dialogSystemScope = false;
          this.loadingForm = false;
        }
      },
      error: (err) => {
        console.error(
          'Error al guardar el registro de alcance de sistema',
          err
        );
        this.loadingForm = false;
      },
    });
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await lastValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await lastValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compañías de usuario:',
        err
      );
    } finally {
    }
  }

  /**
   * Manejador de eliminación de registro de alcance de sistema
   */
  showDeleteSystemScope(event, systemScope: SystemScope) {
    this.confirmationService.confirm({
      target: event.target as EventTarget,
      icon: 'pi pi-exclamation-triangle',
      header: 'Confirmar eliminación',
      message: '¿Estás seguro de que deseas eliminar este registro?',
      acceptLabel: 'Sí',
      rejectLabel: 'No',
      accept: () => {
        this.serverService
          .deleteData(`/api/system-scopes/${systemScope.id}`)
          .subscribe({
            next: (response) => {
              if (response.data) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'OK',
                  detail: 'Registro eliminado correctamente',
                });
                this.getSystemScopes();
              }
            },
            error: (error) => {
              console.error('Error eliminando el registro', error);
            },
          });
      },
    });
  }

  /**
   * Abrir documento existente de contexto de organización
   */
  openDocument() {
    window.open(this.selectedSystemScope?.document, '_blank');
  }
}
