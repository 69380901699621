import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ServerService } from 'src/app/services/server.service';
import { numberScale } from 'src/app/utils';

@Component({
  selector: 'app-dual-materiality',
  templateUrl: './dual-materiality.page.html',
  styleUrls: ['./dual-materiality.page.scss'],
})
export class DualMaterialityPage implements OnInit {
  companyId: any;
  model: any[] = [];
  loading: boolean = false;
  totales: object = {};
  dialogDualMateriality: boolean = false;
  formDualMateriality: FormGroup;
  numberScale = numberScale;
  frequencies = ['Frecuente', 'Probable', 'Ocasional', 'Posible', 'Improbable'];
  impacts = ['Insignificante', 'Menor', 'Moderado', 'Mayor', 'Catastrófico'];
  riskMatrixColors = [
    ['high', 'high', 'high', 'high', 'high'],
    ['medium', 'high', 'high', 'high', 'high'],
    ['low', 'medium', 'high', 'high', 'high'],
    ['low', 'low', 'medium', 'high', 'high'],
    ['low', 'low', 'low', 'medium', 'high'],
  ];
  riskRecords: any[] = [];

  constructor(
    private serverService: ServerService,
    private router: Router,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.formDualMateriality = this.fb.group({
      step1_field1_text: [''],
      step1_field1_document: [''],
      step1_field2_text: [''],
      step1_field2_document: [''],
      step1_field3_text: [''],
      step1_field3_document: [''],

      step2_field1_text: [''],
      step2_field1_document: [''],
      step2_field2_text: [''],
      step2_field2_document: [''],

      step3_field1_text: [''],
      step3_field1_document: [''],
      step3_field1_value: [''],
      step3_field2_text: [''],
      step3_field2_document: [''],
      step3_field2_value: [''],

      step4_field1_text: [''],
      step4_field1_document: [''],
      step4_field2_text: [''],
      step4_field2_document: [''],

      step5_field1_text: [''],
      step5_field1_document: [''],
      step5_field2_text: [''],
      step5_field2_document: [''],
    });
    this.companyId = sessionStorage.getItem('companyId');
    this.recuperarDatosMenu();
    // Generar datos aleatorios mock para items de matriz de riesgos
    this.generateMockData();
  }

  /**
   * Definir estilos en matriz de riesgos
   * @param rowIndex
   * @param colIndex
   * @returns
   */
  getCellClass(rowIndex: number, colIndex: number): string {
    return this.riskMatrixColors[rowIndex][colIndex];
  }

  /**
   * Función que filtra los registros para devolver solo aquellos que coinciden con la frecuencia e impacto dados e imprimirlos en la celda actual
   * @param frequency
   * @param impact
   * @returns
   */
  getRecordsForCell(frequency: string, impact: string): any[] {
    return this.riskRecords.filter(
      (record) => record.frequency === frequency && record.impact === impact
    );
  }

  /**
   * Generador de datos mock en las celdas, se genera entre 1 y 3 items por celda
   */
  generateMockData(): void {
    let recordNumber = 1;
    for (let i = 0; i < this.frequencies.length; i++) {
      for (let j = 0; j < this.impacts.length; j++) {
        const numberOfRecords = Math.floor(Math.random() * 6) + 1;
        for (let k = 0; k < numberOfRecords; k++) {
          this.riskRecords.push({
            name: `P${recordNumber++}`,
            frequency: this.frequencies[i],
            impact: this.impacts[j],
          });
        }
      }
    }
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    // Obtengo id de menú a partir de id de empresa
    this.serverService
      .getData('/api/menusWithIndicatorName/' + this.companyId)
      .subscribe({
        next: (response) => {
          this.model = response.data;
          //console.log('Data', this.model);
          //variables locales
          let menu = this.model['items'],
            padre: any,
            hijos: any,
            nietos: any;
          //console.log('Menu', menu);
          //recorrer menu bbdd para calculo de totales
          for (let key in menu) {
            //primer nivel
            padre = menu[key];
            //build response object
            this.totales[padre.label] = {
              total: 0,
              cualitativos: 0,
              cuantitativos: 0,
              cualitativosValid: 0,
              cuantitativosValid: 0,
            };
            for (let key in padre.items) {
              //segundo nivel
              hijos = padre.items[key];
              //build response object
              this.totales[padre.label][hijos.label] = {
                total: 0,
                cualitativos: 0,
                cuantitativos: 0,
                cualitativosValid: 0,
                cuantitativosValid: 0,
              };
              for (let key in hijos.items) {
                //tercer nivel
                nietos = hijos.items[key];
                //build response object
                this.totales[padre.label][hijos.label][nietos.label] = {
                  total: 0,
                  cualitativos: 0,
                  cuantitativos: 0,
                  cualitativosValid: 0,
                  cuantitativosValid: 0,
                };
                //calculo de totales
                let totalIndicators: number = 0,
                  cualitativos: number = 0,
                  cuantitativos: number = 0,
                  cualitativosValid: number = 0,
                  cuantitativosValid: number = 0;
                for (let key in nietos.indicatorNames) {
                  if (nietos.indicatorNames[key].type == 'qualitative') {
                    cualitativos++;
                    if (nietos.indicatorNames[key].validated) {
                      cualitativosValid++;
                    }
                  } 
                  if (nietos.indicatorNames[key].type == 'quantitative') {
                    cuantitativos++;
                    if (nietos.indicatorNames[key].validated) {
                      cuantitativosValid++;
                    }
                  }                  
                  totalIndicators++;
                }
                //agregar valores acumulados a cada nivel del objeto response
                this.totales[padre.label].total += totalIndicators;
                this.totales[padre.label].cualitativos += cualitativos;
                this.totales[padre.label].cuantitativos += cuantitativos;
                this.totales[padre.label].cualitativosValid += cualitativosValid;
                this.totales[padre.label].cuantitativosValid += cuantitativosValid;
                this.totales[padre.label][hijos.label].total += totalIndicators;
                this.totales[padre.label][hijos.label].cualitativos += cualitativos;
                this.totales[padre.label][hijos.label].cuantitativos += cuantitativos;
                this.totales[padre.label][hijos.label].cualitativosValid += cualitativosValid;
                this.totales[padre.label][hijos.label].cuantitativosValid += cuantitativosValid;
                this.totales[padre.label][hijos.label][nietos.label].total += totalIndicators;
                this.totales[padre.label][hijos.label][nietos.label].cualitativos += cualitativos;
                this.totales[padre.label][hijos.label][nietos.label].cuantitativos += cuantitativos;
                this.totales[padre.label][hijos.label][nietos.label].cualitativosValid += cualitativosValid;
                this.totales[padre.label][hijos.label][nietos.label].cuantitativosValid += cuantitativosValid;
              }
            }
          }
          //ocultar carga
          this.loading = true;
          //console.log('Totales', this.totales);
        },
        error: (err) => {
          console.log('Error al obtener datos del menú', err);
          this.loading = true;
        },
      });
  }

  /**
   * Redirección a detalle de indicador
   * @param indicator
   */
  redirect(indicator) {
    if (indicator.type == 'qualitative') {
      this.router.navigate([
        'base-qualitative/' + indicator.name + '/' + indicator.id,
      ]);
    } else {
      this.router.navigate([
        'base-indicator/' + indicator.name + '/' + indicator.id,
      ]);
    }
  }

  /**
   * Manejador de modal de formulario de doble materialidad
   */
  showDualMaterialityDialog() {
    this.dialogDualMateriality = true;
  }

  /**
   * Manejador de cierre de modal de doble materialidad
   */
  handleHideDualMateriality() {}

  /**
   * Enviar formulario de doble materialidad
   */
  onSubmitForm() {
    console.log('Formulario OK');
  }
}
