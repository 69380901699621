import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { DualMaterialityPageRoutingModule } from './dual-materiality-routing.module';

import { DualMaterialityPage } from './dual-materiality.page';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { InputTextModule } from 'primeng/inputtext';
import { FileUploadModule } from 'primeng/fileupload';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DialogModule } from 'primeng/dialog';
import { StepperModule } from 'primeng/stepper';
import { CardModule } from 'primeng/card';
import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { Dropdown, DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ButtonModule,
    ToastModule,
    CardModule,
    DropdownModule,
    ChipModule,
    ReactiveFormsModule,
    InputTextareaModule,
    AccordionModule,
    ConfirmDialogModule,
    ConfirmPopupModule,
    DialogModule,
    StepperModule,
    InputTextModule,
    FileUploadModule,
    IonicModule,
    DualMaterialityPageRoutingModule,
    TooltipModule
  ],
  declarations: [DualMaterialityPage],
  providers: [ConfirmationService, MessageService],
})
export class DualMaterialityPageModule {}
