import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TreeNode } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { Indicator } from 'src/app/models/indicator';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-business-networking',
  templateUrl: './business-networking.page.html',
  styleUrls: ['./business-networking.page.scss'],
})
export class BusinessNetworkingPage implements OnInit {
  loading: boolean = true;
  companyId: any;
  frequencies = ['Frecuente', 'Probable', 'Ocasional', 'Posible', 'Improbable'];
  impacts = ['Insignificante', 'Menor', 'Moderado', 'Mayor', 'Catastrófico'];
  riskRecords: any[] = [];
  dialogNetworking: boolean = false;
  companiesData: any[] = [];
  mainCompany: any;
  formSubsidiaryCompanies: FormGroup;
  selectedNodes!: TreeNode[];
  dialogCompanyIndicators: boolean = false;
  selectedCompany: any;
  indicators: Indicator[] = [];
  loadingModal: boolean = false;
  data: TreeNode[] = [
    {
      expanded: true,
      type: 'company',
      data: {
        name: 'Matriz',
      },
      children: [
        {
          expanded: false,
          type: 'company',
          data: {
            name: 'Filial 1 - 60%',
          },
        },
        {
          expanded: false,
          type: 'company',
          data: {
            name: 'Filial 2 - 60%',
          },
        },
        {
          expanded: false,
          type: 'company',
          data: {
            name: 'Filial 3 - 60%',
          },
        },
        {
          expanded: false,
          type: 'company',
          data: {
            name: 'Filial 4 - 60%',
          },
        },
      ],
    },
  ];
  constructor(
    private serverService: ServerService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.formSubsidiaryCompanies = this.fb.group({
      companies: this.fb.array([]),
    });

    for (let i = 0; i < 5; i++) {
      this.companies.push(this.createCompaniesGroup());
    }
  }

  ngOnInit() {
    this.companyId = sessionStorage.getItem('companyId');
    // Generar datos aleatorios mock para items de matriz de riesgos
    this.loading = false;
  }

  /**
   * Getter de formulario de empresas filiales
   */
  get companies(): FormArray {
    return this.formSubsidiaryCompanies.get('companies') as FormArray;
  }

  createCompaniesGroup(): FormGroup {
    return this.fb.group({
      percentage: [''],
    });
  }

  /**
   * Obtener listado de empresas
   */
  async getCompanies() {
    try {
      const companiesResponse = await lastValueFrom(
        this.serverService.getDataUsersApi('/api/companies')
      );

      if (companiesResponse) {
        this.companiesData = companiesResponse.data
          ? companiesResponse.data
          : [];
      }
    } catch (err) {
      console.error('Error al obtener listado de empresas', err);
    }
  }

  /**
   * Generador de datos mock en las celdas, se genera entre 1 y 3 items por celda
   */
  generateMockData(): void {}

  /**
   * Manejador de modal de formulario de interconexión de empresas
   */
  async showBusinessNetworkingModal() {
    await this.getCompanies();
    this.dialogNetworking = true;
  }

  /**
   * Manejador de modal de visualización de indicadores de una empresa
   */
  async showCompanyIndicators(event) {
    this.loadingModal = true;
    this.selectedCompany = event.node;
    await this.getIndicators();
    this.dialogCompanyIndicators = true;
    this.loadingModal = false;
  }

  /**
   * Obtención de listado de indicadores
   */
  async getIndicators() {
    try {
      // Esperamos a que se resuelva, observable a promesa
      const indicatorResponse = await lastValueFrom(
        this.serverService.getData('/api/indicators/')
      );

      if (indicatorResponse.data) {
        this.indicators = indicatorResponse.data;
      }
    } catch (err) {
      console.error('Error al obtener los indicadores de una empresa', err);
    }
  }

  /**
   * Manejador de cierre de modal de interconexión de empresas
   */
  handleHideBusinessNetworking() {}

  /**
   * Manejador de cierre de modal de indicadores de una empresa
   */
  handleHideCompanyIndicators() {}

  /**
   * Enviar formulario de interconexión de empresas
   */
  onSubmitForm() {
    console.log('Formulario OK');
  }
}
