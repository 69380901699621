import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage implements OnInit {
  public chartData: any[] = [];
  public basicOptions: any;

  constructor() {
    this.initializeChartData();
  }

  initializeChartData() {
    for (let i = 0; i < 12; i++) {
      this.chartData.push({
        labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
        datasets: [
          {
            label: 'Ventas',
            data: [
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
            ],
            fill: false,
            backgroundColor: getRandomColor(),
          },
          {
            label: 'Ingresos',
            data: [
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
              getRandomValue(),
            ],
            fill: false,
            backgroundColor: getRandomColor(),
          },
        ],
        title: getRandomTitle(),
      });
    }

    this.basicOptions = {
      scales: {
        y: {
          beginAtZero: true,
        },
      },
    };
  }

  ngOnInit() {}
}

const colorArray = [
  '#1992D4',
  '#27AB83',
  '#F9C404',
  '#00B9C6',
  '#C74B95',
  '#4C63B6',
  '#00AE99',
  '#DE911D',
  '#676C7E',
  '#BA6FF4',
  '#DB272D',
];

const valueArray = [
  5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 61, 69, 75, 80, 85, 90, 95,
];

const titleArray = [
  'Residuos',
  'Medio Ambiente',
  'Emisiones',
  'Calidad',
  'Almacén',
];

function getRandomColor() {
  return colorArray[Math.floor(Math.random() * colorArray.length)];
}

function getRandomValue() {
  return valueArray[Math.floor(Math.random() * valueArray.length)];
}

function getRandomTitle() {
  return titleArray[Math.floor(Math.random() * titleArray.length)];
}