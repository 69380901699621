import { Component, OnInit } from '@angular/core';
import { LayoutService } from '../../layout/service/app.layout.service';
import { ServerService } from '../../services/server.service';
import { Indicator } from '../../models/indicator';
import { Router } from '@angular/router';
import { concatMap, firstValueFrom, of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { indicatorCertificate, wordFilterOptions } from 'src/app/utils';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.page.html',
  styleUrls: ['./panel.page.scss'],
})
export class PanelPage implements OnInit {
  public basicOptions: any;
  model: any[] = [];
  menuData: any;
  companyId: any;
  indicators: Indicator[] = [];
  filteredIndicators: any[] = [];
  itemFromUrl: any;
  data: any;
  icon: string | undefined;
  options: any;
  indicatorId: number = 0;
  loadingWord: boolean = false;
  dialogFilter: boolean = false;
  loadingModal: boolean = false;
  rangeDates: any[] = [];
  loading: boolean = false;
  totales: object = {};
  indicatorCertificate = indicatorCertificate;
  wordFilterOptions = wordFilterOptions;
  selectedFilter: number | null = null;
  chartPieData: any;
  chartPieOptions: any;
  chartBarData: any;
  chartBarOptions: any;
  dataGraphicPie: any;
  dataGraphicBar: any;
  userByCompany: any;
  user: any;
  filteredIndicatorsByDates: any[] = [];

  constructor(
    public layoutService: LayoutService,
    private serverService: ServerService,
    private router: Router,
    private messageService: MessageService,
    public permissionService: PermissionsService
  ) {}

  async ngOnInit() {
    try {
      this.companyId = sessionStorage.getItem('companyId');
      await this.getUserByCompany();
      await this.recuperarDatosMenu();
      this.showGraphic('pie');
      this.showGraphic('bar');
    } catch (error) {
      console.error('Error al recuperar los datos del menú:', error);
    }
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await firstValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await firstValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compaías de usuario:',
        err
      );
    }
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  async recuperarDatosMenu() {
    // Obtengo id de menú a partir de id de empresa
    const response = await firstValueFrom(
      this.serverService.getData(
        '/api/menusWithIndicatorName/' + this.companyId
      )
    );
    this.model = response.data;
    //inicializar objeto datos grafico circular
    this.dataGraphicPie = {
      qualitative: 0,
      quantitative: 0,
      not_assigned: 0,
    };
    //inicializar objeto datos grafico barras
    this.dataGraphicBar = {
      label: [],
      value: [],
    };
    //variables locales
    let menu = this.model['items'],
      padre: any,
      hijos: any,
      nietos: any;
    //recorrer menu bbdd para calculo de totales
    for (let key in menu) {
      //primer nivel
      padre = menu[key];
      //agregar valores para grafico barras
      this.dataGraphicBar.label.push(padre.label);
      //build response object
      this.totales[padre.label] = {
        total: 0,
        cualitativos: 0,
        cuantitativos: 0,
        cualitativosValid: 0,
        cuantitativosValid: 0,
      };
      for (let key in padre.items) {
        //segundo nivel
        hijos = padre.items[key];
        //build response object
        this.totales[padre.label][hijos.label] = {
          total: 0,
          cualitativos: 0,
          cuantitativos: 0,
          cualitativosValid: 0,
          cuantitativosValid: 0,
        };
        for (let key in hijos.items) {
          //tercer nivel
          nietos = hijos.items[key];
          //build response object
          this.totales[padre.label][hijos.label][nietos.label] = {
            total: 0,
            cualitativos: 0,
            cuantitativos: 0,
            cualitativosValid: 0,
            cuantitativosValid: 0,
          };
          //calculo de totales
          let totalIndicators: number = 0,
            cualitativos: number = 0,
            cuantitativos: number = 0,
            cualitativosValid: number = 0,
            cuantitativosValid: number = 0;
          for (let key in nietos.indicatorNames) {
            if (nietos.indicatorNames[key].type == 'qualitative') {
              cualitativos++;
              if (nietos.indicatorNames[key].validated) {
                cualitativosValid++;
              }
            }
            if (nietos.indicatorNames[key].type == 'quantitative') {
              cuantitativos++;
              if (nietos.indicatorNames[key].validated) {
                cuantitativosValid++;
              }
            }
            totalIndicators++;
          }

          //agregar valores para grafico circular
          this.dataGraphicPie.qualitative += cualitativos;
          this.dataGraphicPie.quantitative += cuantitativos;
          this.dataGraphicPie.not_assigned +=
            totalIndicators - (cualitativos + cuantitativos);

          //agregar valores acumulados a cada nivel del objeto response
          this.totales[padre.label].total += totalIndicators;
          this.totales[padre.label].cualitativos += cualitativos;
          this.totales[padre.label].cuantitativos += cuantitativos;
          this.totales[padre.label].cualitativosValid += cualitativosValid;
          this.totales[padre.label].cuantitativosValid += cuantitativosValid;
          this.totales[padre.label][hijos.label].total += totalIndicators;
          this.totales[padre.label][hijos.label].cualitativos += cualitativos;
          this.totales[padre.label][hijos.label].cuantitativos += cuantitativos;
          this.totales[padre.label][hijos.label].cualitativosValid +=
            cualitativosValid;
          this.totales[padre.label][hijos.label].cuantitativosValid +=
            cuantitativosValid;
          this.totales[padre.label][hijos.label][nietos.label].total +=
            totalIndicators;
          this.totales[padre.label][hijos.label][nietos.label].cualitativos +=
            cualitativos;
          this.totales[padre.label][hijos.label][nietos.label].cuantitativos +=
            cuantitativos;
          this.totales[padre.label][hijos.label][
            nietos.label
          ].cualitativosValid += cualitativosValid;
          this.totales[padre.label][hijos.label][
            nietos.label
          ].cuantitativosValid += cuantitativosValid;
        }
      }
      //agregar valores para grafico barras
      this.dataGraphicBar.value.push(this.totales[padre.label].total);
    }
    //ocultar carga
    this.loading = true;
    //console.log('Totales', this.totales);
    return true;
  }

  /**
   * TEMPORAL: asigna aleatoriamente un tipo de certificado a cada indicador
   * @returns
   */
  getRandomIndicatorCertificate() {
    const randomIndex = Math.floor(
      Math.random() * this.indicatorCertificate.length
    );
    return this.indicatorCertificate[randomIndex];
  }

  redirect(indicator) {
    if (indicator.type === 'qualitative') {
      this.router.navigate([`base-qualitative/${indicator.id}`]);
    }
    if (indicator.type === 'quantitative') {
      this.router.navigate([`base-indicator/${indicator.id}`]);
    }
  }

  /**
   * Función para parsear la respuesta JSON que devuelve el servidor.
   * @param {Object} response - Respuesta devuelta por el servidor.
   * @returns {Array} - Array con los elementos del menú.
   */
  parsearRespuesta(response) {
    return JSON.parse(response.data.json_menu);
  }

  downloadXBRL(item) {
    console.log(item);
  }

  /**
   * Manejador de modal de filtro de indicadores y obtengo todos los indicadores
   */
  handleFilterModal() {
    this.loadingModal = true;
    this.serverService.getData('/api/getAllWithActions').subscribe({
      next: (response) => {
        if (response.data) {
          this.loadingModal = false;
          this.dialogFilter = true;
          this.filteredIndicators = response.data;
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail: 'No hay indicadores registrados para aplicar filtrados',
          });
        }
      },
      error: (err) => {
        console.error('Error al obtener indicadores', err);
      },
    });
  }

  /**
   * Descargar archivo Word de los indicadores, según filtro seleccionado
   */
  async onClickDownloadWord() {
    // Todos los indicadores
    if (this.selectedFilter === 1) {
      this.loadingWord = true;
      let arrayIndicatorId: (number | undefined)[] = [],
        jsonIndicators: string;
      this.filteredIndicators.forEach((item) => {
        arrayIndicatorId.push(item.id);
      });
      jsonIndicators = JSON.stringify(arrayIndicatorId);

      this.processWordFile(jsonIndicators);
    }

    // Rango de fechas
    if (this.selectedFilter === 2) {
      if (this.rangeDates.length > 0) {
        this.loadingWord = true;
        this.rangeDates = [
          new Date(this.rangeDates[0]),
          new Date(this.rangeDates[1]),
        ];
        await this.filterIndicatorsByDateRange();

        let arrayIndicatorId: (number | undefined)[] = [],
          jsonIndicators: string;
        this.filteredIndicatorsByDates.forEach((item) => {
          arrayIndicatorId.push(item.id);
        });
        jsonIndicators = JSON.stringify(arrayIndicatorId);

        this.processWordFile(jsonIndicators);
      } else {
        return this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Debe seleccionar un rango de fechas',
        });
      }
    }

    // ESRS
    if (this.selectedFilter === 3) {
      this.loadingWord = true;
      const esrsIndicators = this.filteredIndicators.filter((item) =>
        item['standards'].some((subitem) => subitem.standard_id === 1)
      );

      let arrayIndicatorId: (number | undefined)[] = [],
        jsonIndicators: string;
      esrsIndicators.forEach((item) => {
        arrayIndicatorId.push(item.id);
      });
      jsonIndicators = JSON.stringify(arrayIndicatorId);

      this.processWordFile(jsonIndicators);
    }

    // GRI
    if (this.selectedFilter === 4) {
      this.loadingWord = true;
      const griIndicators = this.filteredIndicators.filter((item) =>
        item['standards'].some((subitem) => subitem.standard_id === 2)
      );

      let arrayIndicatorId: (number | undefined)[] = [],
        jsonIndicators: string;
      griIndicators.forEach((item) => {
        arrayIndicatorId.push(item.id);
      });
      jsonIndicators = JSON.stringify(arrayIndicatorId);

      this.processWordFile(jsonIndicators);
    }
  }

  /**
   * Función auxiliar para procesado y descarga de archivo Word
   * @param jsonIndicators
   */
  processWordFile(jsonIndicators) {
    if (JSON.parse(jsonIndicators).length > 0) {
      this.serverService
        .sendData(
          `/api/getWordMultiIndicator/${jsonIndicators}`,
          jsonIndicators
        )
        .subscribe({
          next: (response) => {
            if (response) {
              this.loadingWord = false;
              this.dialogFilter = false;
              this.selectedFilter = null;
              this.rangeDates = [];
              window.open(response[0], '_blank');
              return this.messageService.add({
                severity: 'success',
                summary: 'Descarga exitosa',
                detail: 'El archivo Word ha sido generado y descargado',
              });
            }
          },
          error: (err) => {
            this.loadingWord = false;
            console.error('Error al descargar el archivo Word:', err);
          },
        });
    } else {
      this.loadingWord = false;
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'No se han encontrados indicadores para este filtro',
      });
    }
  }

  /**
   * Filtrado de indicadores por fecha de evidencias
   */
  async filterIndicatorsByDateRange() {
    const [startDate, endDate] = this.rangeDates;

    // Comprobamos si las fechas son válidas
    if (!startDate || !endDate) {
      return;
    }

    this.filteredIndicatorsByDates = this.filteredIndicators
      .map((indicator) => {
        // Comprobamos si el indicador tiene acciones
        if (!indicator.actions || indicator.actions.length === 0) {
          return null; // Si no tiene acciones, lo excluimos
        }

        const filteredActions = indicator.actions
          .map((action) => {
            // Comprobamos si la acción tiene evidencias
            if (!action.evidences || action.evidences.length === 0) {
              return null; // Si no tiene evidencias, excluimos la acción
            }

            const filteredEvidences = action.evidences.filter((evidence) => {
              const evidenceDate = new Date(evidence.date);
              return evidenceDate >= startDate && evidenceDate <= endDate;
            });

            // Si la acción tiene evidencias dentro del rango de fechas, devolvemos la acción con las evidencias filtradas
            if (filteredEvidences.length > 0) {
              return { ...action, evidences: filteredEvidences };
            }
            return null; // Si no tiene evidencias en el rango, devolvemos null
          })
          .filter((action) => action !== null); // Filtramos las acciones sin evidencias en el rango

        // Si el indicador tiene acciones con evidencias filtradas, lo devolvemos
        if (filteredActions.length > 0) {
          return { ...indicator, actions: filteredActions };
        }
        return null; // Si no tiene acciones en el rango, devolvemos null
      })
      .filter((indicator) => indicator !== null); // Filtramos los indicadores que no tienen acciones en el rango
  }

  /**
   * Check de indicador con un estándar
   */
  hasStandard(indicator, standardId: number): boolean {
    return indicator.standards.some(
      (standard) => standard.standard_id === standardId
    );
  }

  /**
   * Check de indicador con ambos estándares
   * @param indicator
   * @param standardId1
   * @param standardId2
   * @returns
   */
  hasBothStandards(
    indicator,
    standardId1: number,
    standardId2: number
  ): boolean {
    return (
      this.hasStandard(indicator, standardId1) &&
      this.hasStandard(indicator, standardId2)
    );
  }

  showGraphic(type) {
    if (type == 'pie') {
      this.chartPieData = {
        labels: ['Cualitativos', 'Cuantitativos', 'Sin Asignar'],
        datasets: [
          {
            data: [
              this.dataGraphicPie.qualitative,
              this.dataGraphicPie.quantitative,
              this.dataGraphicPie.not_assigned,
            ],
            backgroundColor: [
              'rgba(51, 227, 255)', // Azul claro mediano
              'rgba(106, 90, 205)', // Púrpura mediana
              'rgba(255, 159, 64)', // Naranja
            ],
            borderWidth: 0,
          },
        ],
      };

      this.chartPieOptions = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
        },
      };
    }
    if (type == 'bar') {
      this.chartBarData = {
        labels: this.dataGraphicBar.label,
        datasets: [
          {
            label: 'Total de indicadores por sección',
            data: this.dataGraphicBar.value,
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)', // Rojo
              'rgba(54, 162, 235, 0.2)', // Azul
              'rgba(255, 206, 86, 0.2)', // Amarillo
              'rgba(75, 192, 192, 0.2)', // Verde agua
              'rgba(153, 102, 255, 0.2)', // Violeta
              'rgba(255, 159, 64, 0.2)', // Naranja
              'rgba(199, 199, 199, 0.2)', // Gris claro
              'rgba(255, 99, 71, 0.2)', // Tomate
              'rgba(60, 179, 113, 0.2)', // Verde esmeralda
              'rgba(106, 90, 205, 0.2)', // Púrpura mediana
              'rgba(218, 165, 32, 0.2)', // Dorado
              'rgba(244, 164, 96, 0.2)', // Madera tostada
              'rgba(255, 69, 0, 0.2)', // Naranja rojizo
              'rgba(0, 128, 128, 0.2)', // Verde azulado
              'rgba(70, 130, 180, 0.2)', // Acero azulado
              'rgba(127, 255, 212, 0.2)', // Aguamarina
              'rgba(46, 139, 87, 0.2)', // Verde oscuro
              'rgba(221, 160, 221, 0.2)', // Ciruela
              'rgba(255, 215, 0, 0.2)', // Oro
              'rgba(100, 149, 237, 0.2)', // Azul maíz
            ],
            borderColor: [
              'rgb(255, 99, 132)', // Rojo
              'rgb(54, 162, 235)', // Azul
              'rgb(255, 206, 86)', // Amarillo
              'rgb(75, 192, 192)', // Verde agua
              'rgb(153, 102, 255)', // Violeta
              'rgb(255, 159, 64)', // Naranja
              'rgb(199, 199, 199)', // Gris claro
              'rgb(255, 99, 71)', // Tomate
              'rgb(60, 179, 113)', // Verde esmeralda
              'rgb(106, 90, 205)', // Púrpura mediana
              'rgb(218, 165, 32)', // Dorado
              'rgb(244, 164, 96)', // Madera tostada
              'rgb(255, 69, 0)', // Naranja rojizo
              'rgb(0, 128, 128)', // Verde azulado
              'rgb(70, 130, 180)', // Acero azulado
              'rgb(127, 255, 212)', // Aguamarina
              'rgb(46, 139, 87)', // Verde oscuro
              'rgb(221, 160, 221)', // Ciruela
              'rgb(255, 215, 0)', // Oro
              'rgb(100, 149, 237)', // Azul maíz
            ],
            borderWidth: 1,
          },
        ],
      };

      this.chartBarOptions = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
      };
    }
  }

  /**
   * Función para descargar indicadores padre y sus descendientes
   */
  downloadGrandchildrenWords(items, level: number) {
    const itemsToDownload: any[] = [];

    // Según el nivel que estemos, obtenemos los id de distinta manera
    if (level === 1) {
      items['items'].forEach((subitem) => {
        subitem['items'].forEach((subsubitem) => {
          subsubitem['indicatorNames'].forEach((subsubsubitem) => {
            itemsToDownload.push(subsubsubitem['id']);
          });          
        });
      });

      this.processWordFile(JSON.stringify(itemsToDownload));
    }

    if (level === 2) {
      items['items'].forEach((subitem) => {
        subitem['indicatorNames'].forEach((subsubsubitem) => {
          itemsToDownload.push(subsubsubitem['id']);
        });
      });

      this.processWordFile(JSON.stringify(itemsToDownload));
    }

    if (level === 3) {
      items['indicatorNames'].forEach((subsubsubitem) => {
        itemsToDownload.push(subsubsubitem['id']);
      });
      
      this.processWordFile(JSON.stringify(itemsToDownload));
    }
  }
}
