<!-- <div class="grid">
  <div class="col-12">
    <h2>Dashboard</h2>
  </div>
  <ng-container *ngFor="let data of chartData">
    <div class="md:col-3 col-12">
      <div class="flex justify-content-center flex-wrap">
        <h4 class="flex align-items-center justify-content-center">
          {{ data.title }}
        </h4>
      </div>
      <p-chart type="bar" [data]="data" [options]="basicOptions"></p-chart>
    </div>
  </ng-container>
</div> -->

<ion-grid>
  <ion-row>
    <ion-col size="12">
      <h2>Dashboard fase onboarding</h2>
    </ion-col>
  </ion-row>
  <ion-row>
    <ng-container *ngFor="let data of chartData">
      <ion-col size-md="3" size="12">
        <ion-card class="card-esg">
          <ion-card-header>
            <ion-card-title>
              <div class="flex justify-content-center flex-wrap">
                <h4 class="flex align-items-center justify-content-center">
                  {{ data.title }}
                </h4>
              </div>
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p-chart
              type="bar"
              [data]="data"
              [options]="basicOptions"
            ></p-chart>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ng-container>
  </ion-row>
</ion-grid>