import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { isTokenGuard } from './guards/token.guard';
import { AutoformEditPageModule } from './pages/autoform-edit/autoform-edit.module';
import { InitialStateFieldsPageModule } from './pages/initial-state-fields/initial-state-fields.module';
import { DashboardPageModule } from './pages/dashboard/dashboard.module';
import { PanelPageModule } from './pages/panel/panel.module';
import { DualMaterialityPageModule } from './pages/dual-materiality/dual-materiality.module';
import { BusinessNetworkingPageModule } from './pages/business-networking/business-networking.module';
import { ConcernedPartiesPageModule } from './pages/concerned-parties/concerned-parties.module';
import { OrganizationContextPageModule } from './pages/organization-context/organization-context.module';
import { SystemScopesPageModule } from './pages/system-scopes/system-scopes.module';
import { UsersAssociatesPageModule } from './pages/users-associates/users-associates.module';
import { UsersCustomersPageModule } from './pages/users-customers/users-customers.module';
import { UsersEmployeesPageModule } from './pages/users-employees/users-employees.module';
import { UsersSuppliersPageModule } from './pages/users-suppliers/users-suppliers.module';

@NgModule({
  imports: [
    RouterModule.forRoot(
      [
        {
          path: '',
          component: AppLayoutComponent,
          children: [
            {
              path: 'dashboard',
              loadChildren: () => DashboardPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: '',
              loadChildren: () => DashboardPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'form-objective/:id',
              loadChildren: () =>
                import('./pages/form-objetive/form-objetive.module').then(
                  (m) => m.FormObjetivePageModule
                ),
            },
            {
              path: 'form-initial-state/:id',
              loadChildren: () =>
                import(
                  './pages/form-initial-state/form-initial-state.module'
                ).then((m) => m.FormInitialStatePageModule),
            },
            {
              path: 'initial-state/form/:id',
              loadChildren: () => InitialStateFieldsPageModule,
            },
            {
              path: 'initial-state/:id',
              loadChildren: () => AutoformEditPageModule,
            },
            {
              path: 'test-page',
              loadChildren: () =>
                import('./pages/test-page/test-page.module').then(
                  (m) => m.TestPagePageModule
                ),
              canActivate: [isTokenGuard],
            },
            {
              path: 'form',
              loadChildren: () =>
                import('./pages/form/form.module').then(
                  (m) => m.FormPageModule
                ),
              canActivate: [isTokenGuard],
            },
            {
              path: 'indicadores',
              loadChildren: () =>
                import('./pages/menu-indicator/menu-indicator.module').then(
                  (m) => m.MenuIndicatorPageModule
                ),
              canActivate: [isTokenGuard], // Load the module for your new page
            },
            {
              path: 'test',
              loadChildren: () =>
                import('./pages/test-page/test-page.module').then(
                  (m) => m.TestPagePageModule
                ),
              canActivate: [isTokenGuard], // Load the module for your new page
            },
            {
              path: 'action-permissions',
              loadChildren: () =>
                import(
                  './pages/action-permissions/action-permissions.module'
                ).then((m) => m.ActionPermissionsPageModule),
              canActivate: [isTokenGuard], // Load the module for your new page
            },
            {
              path: 'indicator-permissions',
              loadChildren: () =>
                import(
                  './pages/indicator-permissions/indicator-permissions.module'
                ).then((m) => m.IndicatorPermissionsPageModule),
              canActivate: [isTokenGuard], // Load the module for your new page
            },
            {
              path: 'base-page/:id',
              loadChildren: () =>
                import('./pages/base-page/base-page.module').then(
                  (m) => m.BasePagePageModule
                ),
              canActivate: [isTokenGuard],
            },
            {
              path: 'base-indicator/:id',
              loadChildren: () =>
                import('./pages/base-indicator/base-indicator.module').then(
                  (m) => m.BaseIndicatorPageModule
                ),
            },
            {
              path: 'base-qualitative/:id',
              loadChildren: () =>
                import('./pages/base-qualitative/base-qualitative.module').then(
                  (m) => m.BaseQualitativePageModule
                ),
            },
            {
              path: 'base-test/:indicator.name',
              loadChildren: () =>
                import('./pages/base-test/base-test.module').then(
                  (m) => m.BaseTestPageModule
                ),
            },
            {
              path: 'panel',
              loadChildren: () => PanelPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'dual-materiality',
              loadChildren: () => DualMaterialityPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'business-networking',
              loadChildren: () => BusinessNetworkingPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'concerned-parties',
              loadChildren: () => ConcernedPartiesPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'organization-context',
              loadChildren: () => OrganizationContextPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'system-scopes',
              loadChildren: () => SystemScopesPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'users-associates',
              loadChildren: () => UsersAssociatesPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'users-customers',
              loadChildren: () => UsersCustomersPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'users-employees',
              loadChildren: () => UsersEmployeesPageModule,
              canActivate: [isTokenGuard],
            },
            {
              path: 'users-suppliers',
              loadChildren: () => UsersSuppliersPageModule,
              canActivate: [isTokenGuard],
            },
          ],
          canActivate: [isTokenGuard],
        },
        { path: '**', redirectTo: 'notfound' },
      ],
      { preloadingStrategy: PreloadAllModules, useHash: true }
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
