<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>

<ion-grid class="full-grid">
  <ion-row>
    <div class="header"></div>
  </ion-row>
  <ion-row style="margin-top: -6rem !important">
    <ion-col
      size-xs="12"
      size-sm="12"
      size-md="12"
      class="margin-col-start margin-col-end"
    >
      <!-- Listado de indicadores -->
      <p-card header="Resumen indicadores: doble materialidad">
        @if(!loading){
        <ion-col size-xs="12" size-sm="12" size-md="6" class="margin-col-end">
          <div class="spinner-container">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </ion-col>
        } @else {
        <!-- Matriz de riesgos -->
        <div class="w-full mb-1">
          <p-accordion class="accordion-indicator">
            <p-accordionTab header="Matriz de riesgos - 2023">
              <div class="risk-matrix">
                <div class="header-row">
                  <div class="header-cell"></div>
                  <div class="header-cell" *ngFor="let impact of impacts">
                    {{ impact }}
                  </div>
                </div>
                <div
                  class="row"
                  *ngFor="let frequency of frequencies; let rowIndex = index"
                >
                  <div class="cell header-cell">{{ frequency }}</div>
                  <div
                    class="cell"
                    *ngFor="let impact of impacts; let colIndex = index"
                    [ngClass]="getCellClass(rowIndex, colIndex)"
                  >
                    <div class="chip-container">
                      <p-chip
                        *ngFor="let record of getRecordsForCell(frequency, impact)"
                        label="{{ record.name }}"
                        styleClass="custom-chip"
                      ></p-chip>
                    </div>
                  </div>
                </div>
              </div>
            </p-accordionTab>

            <p-accordionTab header="Matriz de riesgos - 2024">
              <div class="risk-matrix">
                <div class="header-row">
                  <div class="header-cell"></div>
                  <div class="header-cell" *ngFor="let impact of impacts">
                    {{ impact }}
                  </div>
                </div>
                <div
                  class="row"
                  *ngFor="let frequency of frequencies; let rowIndex = index"
                >
                  <div class="cell header-cell">{{ frequency }}</div>
                  <div
                    class="cell"
                    *ngFor="let impact of impacts; let colIndex = index"
                    [ngClass]="getCellClass(rowIndex, colIndex)"
                  >
                    <div class="chip-container">
                      <p-chip
                        *ngFor="let record of getRecordsForCell(frequency, impact)"
                        label="{{ record.name }}"
                        styleClass="custom-chip"
                      ></p-chip>
                    </div>
                  </div>
                </div>
              </div>
            </p-accordionTab>
          </p-accordion>
        </div>
        <!--inicio bucle primer nivel-->
        <p-accordion *ngFor="let parentKey of model['items']">
          <p-accordionTab>
            <ng-template pTemplate="header">
              <i class=" {{ parentKey.icon }}"></i>
              <span
                class="ml-2"
                style="font-family: 'Inter var', sans-serif !important"
              >
                {{ parentKey.label }}
              </span>

              <div class="totales">
                <span class="ml-3">
                  <i class="pi pi-database"></i>
                  <span
                    class="allindicators"
                    pTooltip="Total Indicadores"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].total }}
                  </span>
                </span>
                <span class="ml-3">
                  <i class="pi pi-file-word"></i>
                  <span
                    class="allindicatorsQualitative"
                    pTooltip="Total Cualitativos"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cualitativos }}
                  </span>
                </span>
                <span class="ml-3">
                  <i class="pi pi-check"></i>
                  <span
                    class="allindicatorsValid"
                    pTooltip="Total Cualitativos Validados"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cualitativosValid }}
                  </span>
                </span>
                <span class="ml-3">
                  <i class="pi pi-chart-bar"></i>
                  <span
                    class="allindicatorsQuantitative"
                    pTooltip="Total Cuantitativos"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cuantitativos }}
                  </span>
                </span>
                <span class="ml-3">
                  <i class="pi pi-check"></i>
                  <span
                    class="allindicatorsValid"
                    pTooltip="Total Cualitativos Validados"
                    tooltipPosition="top"
                  >
                    {{ totales[parentKey.label].cuantitativosValid }}
                  </span>
                </span>
              </div>
            </ng-template>

            <!--inicio bucle segundo nivel-->
            <p-accordion *ngFor="let childKey of parentKey['items']">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <i class=" {{ childKey.icon }}"></i>
                  <span
                    class="ml-2"
                    style="
                      font-weight: 400 !important;
                      font-family: 'Inter var', sans-serif !important;
                    "
                  >
                    {{ childKey.label }}
                  </span>

                  <div class="totales">
                    <span class="ml-3">
                      <i class="pi pi-database"></i>
                      <span
                        class="allindicators"
                        pTooltip="Total Indicadores"
                        tooltipPosition="top"
                      >
                        {{ totales[parentKey.label][childKey.label].total }}
                      </span>
                    </span>
                    <span class="ml-3">
                      <i class="pi pi-file-word"></i>
                      <span
                        class="allindicatorsQualitative"
                        pTooltip="Total Cualitativos"
                        tooltipPosition="top"
                      >
                        {{ totales[parentKey.label][childKey.label].cualitativos
                        }}
                      </span>
                    </span>
                    <span class="ml-3">
                      <i class="pi pi-check"></i>
                      <span
                        class="allindicatorsValid"
                        pTooltip="Total Cualitativos Validados"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].cualitativosValid
                        }}
                      </span>
                    </span>
                    <span class="ml-3">
                      <i class="pi pi-chart-bar"></i>
                      <span
                        class="allindicatorsQuantitative"
                        pTooltip="Total Cuantitativos"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].cuantitativos
                        }}
                      </span>
                    </span>
                    <span class="ml-3">
                      <i class="pi pi-check"></i>
                      <span
                        class="allindicatorsValid"
                        pTooltip="Total Cualitativos Validados"
                        tooltipPosition="top"
                      >
                        {{
                        totales[parentKey.label][childKey.label].cuantitativosValid
                        }}
                      </span>
                    </span>
                  </div>
                </ng-template>

                <!--inicio bucle tercer nivel-->
                <p-accordion *ngFor="let grandchild of childKey['items']">
                  <p-accordionTab>
                    <ng-template pTemplate="header">
                      <i class=" {{ grandchild.icon }}"></i>
                      <span
                        class="ml-2"
                        style="
                          font-weight: 400 !important;
                          font-family: 'Inter var', sans-serif !important;
                        "
                      >
                        {{ grandchild.label }}
                      </span>

                      <div class="totales">
                        <span class="ml-3">
                          <i class="pi pi-database"></i>
                          <span
                            class="allindicators"
                            pTooltip="Total Indicadores"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].total
                            }}
                          </span>
                        </span>
                        <span class="ml-3">
                          <i class="pi pi-file-word"></i>
                          <span
                            class="allindicatorsQualitative"
                            pTooltip="Total Cualitativos"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cualitativos
                            }}
                          </span>
                        </span>
                        <span class="ml-3">
                          <i class="pi pi-check"></i>
                          <span
                            class="allindicatorsValid"
                            pTooltip="Total Cualitativos Validados"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cualitativosValid
                            }}
                          </span>
                        </span>
                        <span class="ml-3">
                          <i class="pi pi-chart-bar"></i>
                          <span
                            class="allindicatorsQuantitative"
                            pTooltip="Total Cuantitativos"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cuantitativos
                            }}
                          </span>
                        </span>
                        <span class="ml-3">
                          <i class="pi pi-check"></i>
                          <span
                            class="allindicatorsValid"
                            pTooltip="Total Cualitativos Validados"
                            tooltipPosition="top"
                          >
                            {{
                            totales[parentKey.label][childKey.label][grandchild.label].cuantitativosValid
                            }}
                          </span>
                        </span>
                      </div>
                    </ng-template>

                    <!--inicio bucle indicadores-->
                    <p
                      *ngFor="let indicator of grandchild.indicatorNames"
                      class="list-item"
                    >
                      <i class="pi pi-sign-in">
                        <span
                          class="ml-2"
                          style="
                            font-weight: 400 !important;
                            font-family: 'Inter var', sans-serif !important;
                          "
                        >
                          {{ indicator.name }}
                        </span>
                      </i>
                      <p-button
                        label="Iniciar doble materialidad"
                        icon="pi pi-plus"
                        (click)="showDualMaterialityDialog()"
                      ></p-button>
                    </p>
                  </p-accordionTab>
                </p-accordion>
              </p-accordionTab>
            </p-accordion>
          </p-accordionTab>
        </p-accordion>
        }
      </p-card>
    </ion-col>
  </ion-row>
</ion-grid>

<!-- Modal para cumplimentar doble materialidad -->
<p-dialog
  header="Doble materialidad"
  [(visible)]="dialogDualMateriality"
  [modal]="true"
  [draggable]="false"
  [resizable]="false"
  [style]="{ width: '80vw' }"
  (onHide)="handleHideDualMateriality()"
>
  <form [formGroup]="formDualMateriality">
    <p-stepper>
      <!-- Paso 1 -->
      <p-stepperPanel header="Paso 1">
        <ng-template
          pTemplate="content"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <!-- Formulario paso 1 -->
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Análisis del entorno: </b> este paso inicial implica un
                  análisis profundo del entorno en el que opera la empresa
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Regulaciones</label>
                  <textarea
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="step1_field1_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Tendencias sectoriales</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    [style]="{ width: '100%' }"
                    [inputStyle]="{ width: '100%' }"
                    formControlName="step1_field2_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="12">
                  <label for="">Expectativas de las partes interasadas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step1_field3_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="flex pt-4 justify-content-end">
            <p-button
              label="Siguiente"
              icon="pi pi-arrow-right"
              iconPos="right"
              (onClick)="nextCallback.emit()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 2 -->
      <p-stepperPanel header="Paso 2">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <!-- Formulario paso 2 -->
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Recolección de información: </b>este paso implica la
                  recopilación de datos y la consulta con las partes interesadas
                  para identificar los temas relevantes
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas internas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step2_field1_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Consultas externas</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step2_field2_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button
              label="Atrás"
              icon="pi pi-arrow-left"
              (onClick)="prevCallback.emit()"
            />
            <p-button
              label="Siguiente"
              icon="pi pi-arrow-right"
              iconPos="right"
              (onClick)="nextCallback.emit()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 3 -->
      <p-stepperPanel header="Paso 3">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <!-- Formulario paso 3 -->
          <div class="">
            <ion-grid>
              <div class="header-container">
                <p>
                  <b>Evaluación de impacto y relevancia</b>
                </p>
              </div>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Impacto</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="step3_field1_value"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    rows="5"
                    pInputTextarea
                    formControlName="step4_field1_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Relevancia financiera</label>
                  <div class="mt-1">
                    <p-dropdown
                      formControlName="step3_field2_value"
                      appendTo="body"
                      [options]="numberScale"
                      optionLabel="label"
                      [style]="{'width':'100%'}"
                      [inputStyle]="{'width':'100%'}"
                      optionValue="value"
                      placeholder="Selecciona un valor"
                    >
                    </p-dropdown>
                  </div>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step3_field2_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button
              label="Atrás"
              icon="pi pi-arrow-left"
              (onClick)="prevCallback.emit()"
            />
            <p-button
              label="Siguiente"
              icon="pi pi-arrow-right"
              iconPos="right"
              (onClick)="nextCallback.emit()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 4 -->
      <p-stepperPanel header="Paso 4">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <!-- Formulario paso 4 -->
          <div class="">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Upstream y Downstream</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step4_field1_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Evaluaciones de Ciclo de Vida (LCA)</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step4_field1_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button
              label="Atrás"
              icon="pi pi-arrow-left"
              (onClick)="prevCallback.emit()"
            />
            <p-button
              label="Siguiente"
              icon="pi pi-arrow-right"
              iconPos="right"
              (onClick)="nextCallback.emit()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>

      <!-- Paso 5 -->
      <p-stepperPanel header="Paso 5">
        <ng-template
          pTemplate="content"
          let-prevCallback="prevCallback"
          let-nextCallback="nextCallback"
          let-index="index"
        >
          <!-- Formulario paso 5 -->
          <div class="">
            <ion-grid>
              <ion-row>
                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Transparencia y exactitud</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step5_field1_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>

                <ion-col size-xs="12" size-sm="12" size-md="6">
                  <label for="">Conformidad con estándares</label>
                  <textarea
                    class="w-full mb-3 mt-1"
                    pInputTextarea
                    rows="5"
                    formControlName="step5_field2_text"
                  ></textarea>
                  <p-fileUpload
                    name="file"
                    chooseLabel="Seleccionar archivo"
                    [customUpload]="true"
                    [showUploadButton]="false"
                    (onSelect)="onFileSelect($event)"
                    (onRemove)="deleteFileSelected($event)"
                    accept=".xlsx, .xls"
                  ></p-fileUpload>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="flex pt-4 justify-content-between">
            <p-button
              label="Atrás"
              icon="pi pi-arrow-left"
              (onClick)="prevCallback.emit()"
            />
            <p-button
              label="Guardar"
              iconPos="right"
              (click)="onSubmitForm()"
            />
          </div>
        </ng-template>
      </p-stepperPanel>
    </p-stepper>
  </form>
</p-dialog>
