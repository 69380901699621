import { ChartModule } from 'primeng/chart';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PanelPageRoutingModule } from './panel-routing.module';
import { PanelPage } from './panel.page';
import { AccordionModule } from 'primeng/accordion';
import { CardModule } from 'primeng/card';
import { ChipModule } from 'primeng/chip';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { DropdownModule } from 'primeng/dropdown';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PanelPageRoutingModule,
    ChartModule,
    AccordionModule,
    CardModule,
    ChipModule,
    ButtonModule,
    TooltipModule,
    DialogModule,
    ToastModule,
    DropdownModule,
    ConfirmPopupModule,
    ButtonModule,
    CalendarModule,
    ProgressSpinnerModule,
  ],
  declarations: [PanelPage],
  providers: [MessageService, ConfirmationService],
})
export class PanelPageModule {}
